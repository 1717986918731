export const locale = {
  lang: 'sp',
  data: {
    LOGIN: {
      SUCCESSFULLY: 'Has iniciado sesión correctamente.',
      REGISTERED_SUCCESSFULLY: 'Te has registrado exitosamente.',
      WELCOME: 'Bienvenido',
      WELCOME_LOGIN: 'Bienvenido a Factura360',
      ALREADY_ACCOUNT_EXIST: 'Bienvenido a Factura360',
      TITLE: 'Ingresar',
      EMAIL: {
        LABEL: 'Nombre de usuario',
        PLEASE_ENTER:
          'Por favor ingrese su dirección correo electr&oacute;nico.',
        PLEASE_VALI:
          'Por favor ingrese un correo electr&oacute;nico v&aacute;lido.',
      },
      PASSWORD: {
        LABEL: 'Contraseña',
        PLEASE_ENTER: 'Por favor ingrese su Contraseña.',
      },
      REMEMBER: 'Recordar datos',
      BUTTON: 'Ingresar',
      MESSAGES: {
        FAIL: 'Acceso incorrecto',
        ACCESS_DENIED: 'Usuario / Contraseña Incorrectos',
      },
    },
    FORGOT_PASSWORD: {
      TITLE: 'Olvido su Contraseña.',
      BUTTON: 'Enviar correo',
      SIGN_IN: 'Iniciar sesión',
      SIGN_UP: 'Regístrate',
      NEW_REGISTER: 'Crea tu cuenta sin compromiso',
      CUSTOMER: 'Descarga CFDI para clientes / empleados',
    },
    REGISTER: {
      TITLE: 'Registro',
      FIRST_NAME: {
        LABEL: 'Nombre completo o Razón social',
        PLEASE_ENTER: 'Se requiere el nombre completo o Razón social.',
      },
      LAST_NAME: {
        LABEL: 'Apellido Paterno',
        PLEASE_ENTER: 'Se requiere el Último nombre.',
      },
      MOTHERS_LAST_NAME: {
        LABEL: 'Apellido materno',
        PLEASE_ENTER: 'El apellido de la madre es obligatorio.',
      },
      RFC: {
        LABEL: 'RFC (Favor de capturarlo con mayúsculas y sin espacios)',
        PLEASE_ENTER: 'RFC es necesario.',
        PLEASE_VALID: 'RFC no válido.',
        ALREADY_EXIST: 'RFC ya existe',
      },
      EMAIL: {
        LABEL: 'Correo electrónico',
        PLEASE_ENTER: 'Correo electrónico es requerido',
        PLEASE_VALID: 'Dirección de correo electrónico no válida.',
      },
      USERNAME: {
        LABEL: 'Nombre de usuario',
        PLEASE_ENTER:
          'Nombre de usuario es requerido y debe tener 8 caracteres',
        PLEASE_VALID: 'Usuario invalido',
      },
      RE_PASSWORD: {
        LABEL: 'Confirmar contraseña',
        PLEASE_ENTER: 'Reescribir contraseña es necesaria.',
      },
      CURP: {
        LABEL: 'CURP',
        PLEASE_ENTER: 'Se requiere CURP.',
        PLEASE_VALID: 'CURP no válido.',
      },
      COUPON: {
        LABEL: 'Cupón',
        PLEASE_ENTER: 'Se requiere cupón.',
        PLEASE_VALID: 'Cupón no válido.',
      },
      EXPIRY_DATE: {
        LABEL: 'Fecha de vencimiento de anualidad',
        PLEASE_ENTER: 'Fecha de vencimiento es requerido',
      },
      PASSWORD: {
        LABEL: 'Contraseña',
        PLEASE_ENTER: 'se requiere contraseña.',
        CONFIRM_ERROR:
          'Confirmar que las contraseñas no coinciden con la contraseña.',
        MIN_LENGTH: 'La contraseña debe tener un mínimo de 6 caracteres.',
      },
      TERM_OF_USE: {
        LABEL: 'Acepto los términos y condiciones del servicio',
        PLEASE_CHECK: 'Acepta las condiciones de uso',
        TEXT: 'Términos de uso',
      },
      BUTTON: 'Regístrate',
      MESSAGES: {
        FAIL: 'Registro fallido',
      },
    },
    ACCESS: {
      TITLE: 'Panel De Acceso',
      MASTER_RFC: {
        LABEL: 'RFC Master',
        PLEASE_ENTER: 'Se requiere el RFC Master',
      },
      RECEPTOR_RFC: {
        LABEL: 'Receptor RFC',
        PLEASE_ENTER: 'Se requiere el RFC Master',
      },
      KEYWORD: {
        LABEL: 'Palabra Clave para auto recuperación de Facturas',
        PLEASE_ENTER:
          'Se requiere el Palabra Clave para auto recuperación de Facturas',
      },
      FROM_DATE: {
        LABEL: 'Fecha inicial (opcional)',
        PLEASE_ENTER: 'Se requiere el Fecha a partir de',
      },
      END_DATE: {
        LABEL: 'Fecha final (opcional)',
        PLEASE_ENTER: 'Se requiere el Fecha para',
      },
      ADD_RECORD: {
        LABEL: 'Añadir registro',
      },
      TYPE: {
        LABEL: 'Opciones de descarga',
      },
      BUTTON: 'Enviar',
      MESSAGES: {
        FAIL: 'Datos no encontrados!',
      },
    },
    TEXTBOX: {
      EDIT_CONCEPT_MESSAGE: '¿Está seguro de que desea editar este concepto?',
      STATUS_SUCCESS: 'Proceso Exitoso',
      STATUS_MESSAGE: 'Todos los resultados se podrán visualizar en su reporte',
      STATUS: 'Estado',
      SUCCESS: 'Exitoso',
      CANCELLED: 'Cancelado',
      SHOW: 'Mostrar',
      ADDRECORD: 'Nuevo',
      EXPORTCSV: 'Exportar CSV',
      EXCEL: 'Exportar a Excel',
      Action: 'Acción',
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
      SEARCH_ACTIVE: 'CFDI Vigentes',
      SEARCH_INACTIVE: 'CFDI Cancelados',
      BANKRFC: 'Banco RFC',
      INVOICECODE: 'Código de factura',
      RETENTION_CODE: 'Código de retención',
      STATE: 'Estado',
      COUNTRY: 'País',
      MUNICIPALITY: 'Municipio',
      LOCATION: 'Localidad',
      POSTALCODE: 'Código postal',
      TITLE: 'Nombre',
      VIEW: 'Ver planes',
      APPLY: 'Aplicar planes',
      SEND: 'Enviar',
      DOWNLOAD_XML: 'Descargar XML',
      DOWNLOAD_ASSIGNED_XML: 'Descargar XML timbrado',
      DOWNLOAD_PDF: 'Descargar PDF',
      DOWNLOAD_CANCEL_ACKNOWLEDGMENT: 'Acuse de cancelación',
      DOWNLOAD: 'Descargar',
      SEND_MAIL: 'Enviar correo electrónico',
      CLONE: 'Clon',
      MODIFY_RECORD: 'Modificar',
      EDIT: 'Editar',
      CANCEL_RECORD: 'Eliminar',
      REMOVE: 'Borrar',
      INVOICE_STATUS: 'Estatus del CFDI',
      ADD_EMAIL: 'Añadir más correos electrónicos',
      EMAIL: 'Correo electrónico',
      ADDITIONAL_MESSAGE: 'Mensaje adicional',
      NO_RECORD: 'Ningún record fue encontrado',
      EMAIL_HEAD_MESSAGE: 'Correo electrónico',
      EMAIL_BODY_MESSAGE: 'El documento ha sido enviado exitosamente a ',
      EMAIL_MODAL_TITLE: 'Enviar un correo electrónico',
      CANCEL_MODAL_TITLE: 'Cancelar Retencion',
      CFDI_CANCEL_MODAL_TITLE: 'Cancelar CFDI',
      PAYROLL_CANCEL_MODAL_TITLE: 'Borrar nómina',
      PASSWORD: 'Contraseña de creación de facturas',
      PASSWORD_ERROR: 'Contraseña es requerida',
      INVALID_CERTIFICATE: 'Contraseña de certificado no válida',
      PAYROLL_ERROR1: 'Límite de la serie de nóminas de la empresa cruzada.',
      PAYROLL_ERROR2:
        'Su cuenta no tiene folios disponibles, favor de adquirir un Nuevo paquete de folios',
      INVOICE_ERROR2:
        'Su cuenta no tiene folios disponibles, favor de adquirir un Nuevo paquete de folios',
      PAYROLL_ERROR3: 'Error de servicio',
      PAYROLL_ERROR4: 'Master ha sido bloqueado.',
      PAYROLL_ERROR6: 'Contraseña de certificado no válida.',
      REMARK: 'Motivo de cancelación',
      REMARK_ERROR: 'Motivo de cancelación es requerido',
      UUID_SUBSTITUTE: 'UUID substituto',
      STATUS_MODAL_TITLE: 'Estatus del CFDI',
      CANCEL_HEAD_MESSAGE: 'Cancelar',
      CANCEL_BODY_MESSAGE: 'Registro cancelado con éxito',
      ERROR_PASSWORD_MESSAGE:
        'Contraseña de facturación incorrecta. Si usted subio recientemente archivos CSD  verifique la contraseña de llave privada ya que no es la misma que el SAT tiene registrada. Posteriormente suba nuevamente sus CSD en carga de certificados.',
      REMOVE_TITLE: 'Borrar registro',
      REMOVE_MESSAGE: '¿ Está seguro de eliminar este registro?',
      EDIT_TITLE: 'Editar registro',
      EDIT_MESSAGE: '¿Está seguro de editar este registro?',
      CANCEL: 'Cancelar',
      ACCEPT: 'Aceptar',
      DELETED: 'Suprimido!',
      DELETED_MESSAGE: 'El registro se ha eliminado.',
      SUCCESS_HEAD: 'Éxito',
      SUCCESS_RECORD_HEAD: 'Carga correcta',
      SUCCESS_MESSAGE: 'Registro guardado con éxito.',
      NAME: 'Nombre',
      CODE: 'Código',
      EXCHANGE_RATE: 'Tipo de cambio',
      NAME_ERROR: 'Nombre es requerido',
      RFC_ERROR: 'RFC es requerido',
      YEAR_ERROR: 'Año es requerido',
      CODE_ERROR: 'Código es requerido',
      COUNTRY_ERROR: 'País es requerido',
      STATE_ERROR: 'Estado es requerido',
      POSTAL_CODE_ERROR: 'Código postal es requerido',
      MUNICIPALITY_ERROR: 'Municipio es requerido',
      LOCATION_ERROR: 'Localidad es requerida',
      RETENTION_CODE_ERROR: 'Código de retención es requerido',
      EXCHANGE_RATE_ERROR: 'Tipo de cambio es requerido',
      START_ERROR: 'Comenzar es requerido',
      END_ERROR: 'Fin es requerido',
      STATUS_ERROR: 'Estado es requerido',
      DELETE_ALERT_HEAD: 'Borrar',
      DELETE_ALERT_MESSAGE: '¿Está seguro de que desea eliminar este registro?',
      DELETE_ALERT_CANCEL: 'Cancelar',
      DELETE_ALERT_ACCEPT: 'Aceptar',
      DELETE_ALERT_DELETED: '¡Eliminado!',
      SEARCH: 'Buscar',
      SEARCH_TEXT: 'Buscar...',
      INTERNAL_SERVER_ERROR:
        'Error interno del servidor. Vuelva a intentarlo después de un tiempo.',
      PAYROLL_DATE_ERROR:
        'Por favor ingrese fecha de pago, fecha inicial del pago, fecha final y días pagados.',
      PAYMENT_DATE_ERROR: 'Fecha de pago es requerido.',
      PAYMENT_START_DATE_ERROR: 'Fecha de inicio del pago es requerido.',
      PAYMENT_END_DATE_ERROR: 'Fecha de finalización del pago es requerido.',
      PAYMENT_DETAIL_ADDED: 'Complemento de nómina añadido.',
      PAYMENT_DETAIL_UPDATED: 'Complemento de nómina actualizado.',
    },
    HEADER: {
      NAME: 'Nombre',
      EMAIL: 'Correo electrónico',
      AGE: 'Edad',
      STATUS: 'Estadística',
      ACTIONS: 'Comportamiento',
    },
    SETTING: {
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      SAVE_TITLE: 'Enviar',
      PREVIEW: 'Vista Previa',
      CANCEL_TITLE: 'Cancelar',
      VIEW: 'View',
      APPLY: 'Apply',
      SEND: 'Send',
    },
    SALES: {
      MEMBERSHIP_DATE: 'Fecha de vencimiento de anualidad',
      CERTIFICATE_DATE: 'Fecha en que vencen los certificados cer y key',
      LAST_DOCUMENT: 'Último documento CFDI emitido',
      LAST_PAYROLL: 'Último documento CFDI nómina emitido',
      LAST_RETENCIONES: 'Último documento CFDI retenciones emitido',
      PURCHASE_WEB: 'Paquetes Web (Anualidad/Folios)',
      PURCHASE_BATCH: 'Paquetes Batch (Anualidad/Folios)',
      PURCHASE: 'Comprar',
      TOTAL_SALES: 'Ventas Totales ',
      ALL_TIME_EARNINGS: 'Ganancias',
      TOTAL_EARNINGS: 'Ganancias Totales',
      ORDERS: 'Ordenes',
      ORDER_STATUS: 'Estatus de Ordenes',
      LOW_STOCK: 'Pocas Existencias',
      TOP_PRODUCT_CATEGORIES: 'Categor&iacute;as de Productos',
      DATE_UPDATED: 'Rango de fechas actualizado',
      REVENUE: 'Ingresos Totales',
      SALES_ORDERS: 'Ordenes Vendidas',
      UNITS_SOLD: 'Unidades Vendidas',
      RENEW_EXPIRE_DATE: 'Recuerda renovar tu anualidad que expira el',
      EXPIRE_CER_KEY_DATE:
        'Tus certificados están próximos a vencer, te recomendamos renovarlos lo antes posible.',
      COMPANY_TIPS:
        'Mis empresas: Permite capturar sus datos de Emisor para los documentos fiscales que usted expedirá.',
      PRODUCT_TIPS:
        'Productos: Permite crear sus productos con los campos: unidad de medida, precio y descripción.',
      PART_TIPS: 'Parte: Permite crear las especificaciones de los productos.',
      CUSTOMER_TIPS:
        'Clientes: Permite capturar RFC, nombre y dirección fiscal de sus clientes.',
      DEPARTMENT_TIPS:
        'Departamentos: Permite crear un catálogo con los nombres de sus departamentos o áreas de su empresa / negocio.',
      EXPENSE_TIPS:
        'Percepciones: Permite crear todas las percepciones para recibos de nómina.',
      DISCOUNT_TIPS:
        'Deducciones: Permite crear todas las deducciones para el empleado en un recibo de nómina.',
      OTHER_TIPS:
        'Otros pagos: Permite crear registros de otro tipo de percepciones como Subsidios, Viáticos, etc.',
      EMPLOYEE_TIPS:
        'Empleados: Permite crear registros de sus empleados para la nómina.',
      P_TIPS: 'Recibos de nómina: Abre el módulo nuevo recibo de nómina.',
      P_B_TIPS:
        'Batch nóminas: Abre el módulo para emisión de recibos de nómina CFDI de manera masiva.',
      I_TIPS:
        'Factura Abre el módulo para crear una nueva factura, recibo de honorarios, nota de crédito, etc.',
      I_B_TIPS:
        'Batch facturas: Abre el módulo para emisión de facturas CFDI de manera masiva.',
      R_TIPS: 'Recibos de retención: Abre el módulo nuevo recibo de retención.',
      R_B_TIPS:
        'Batch retención: Abre el módulo para emisión de recibos de retención CFDI de manera masiva.',
      TOTAL_CFDI: 'Disponibles CFDI',
      TOTAL_API: 'Disponibles API/BATCH',
      FULL_SCREEN: 'Pantalla completa',
      LANGUAGE: 'Lenguaje',
      PACKAGE: 'Compre su plan!',
      PAYPAL_HEAD: 'Es un medio de pago fácil, rápido y seguro.',
      PAYPAL_1: 'Puede hacer pago con tarjeta de crédito y débito.',
      PAYPAL_2:
        'Registre en PayPal sus datos financieros una sola vez. No necesitará proporcionarlos en compras posteriores.',
      PAYPAL_3: 'Mas de 100 millones de personas en el mundo utilizan PayPal.',
      PAYPAL_4:
        'PayPal protege su información: No compartimos sus datos financieros.',
      DATE: 'Fecha',
      RECEPTOR:
        'Permite dar de alta al receptor para constancias por pagos o retenciones',
      USER: 'Usuarios. Permite dar de alta usuarios con privilegios y restricciones.',
      TAX: 'Administración de impuestos. Se pueden dar de alta porcentajes especiales para aplicarlos en facturas CFDI',
      DASHBOARD_RECEPTOR: 'Receptor',
      DASHBOARD_TOTAL: 'Total',
      SERIE_FOLIO: 'Serie/Folio',
      VERSION: 'Versión',
      MASTER_MODULE: 'Administrador del Usuario Principal',
      CURRENCY: 'Tipo de Moneda',
      WEB_SERVICE: 'Servicio web',
      TAX_MODULE: 'Administración de Impuestos',
      EMPLOYEE_JOB_TYPE: 'Puesto del Empleado',
      EMPLOYEE_EXTRA_HOUR_TYPE: 'Horas Extra del Empleado',
      COMPANY_MODULE: 'Empresa',
      PRODUCT_MODULE: 'Administración de Producto',
      PART_MODULE: 'Administración de Parte',
      CUSTOMER_MODULE: 'Administración de Clientes',
      RETENTION_RECEPTOR: 'Receptores Retenciones',
      OPERATOR: 'Usuarios',
      EMPLOYEE_DEPARTMENT: 'Departamento',
      EMPLOYEE: 'Empleado',
      EMPLOYEE_EXPENSE: 'Percepciones del Empleado',
      EMPLOYEE_OTHER_TYPE: 'Otro Tipo de Pago',
      EMPLOYEE_OTHER: 'Otros pagos del empleado',
      EMPLOYEE_DISCOUNT: 'Deducciones del empleado',
      INVOICE: 'Gestión de Facturas 4.0',
      PAYROLL: 'Recibo de Nómina 4.0',
      RETENTION: 'Gestion de retenciones 2.0',
      INVOICE_BATCH: 'Facturas Batch 4.0',
      CFDI: 'Nómina Batch 4.0',
      RETENTION_BATCH_SUB: 'Retenciones Batch 2.0',
    },
    PROFILE: {
      PROFILE: 'Configuración',
      MESSAGE: {
        SUCCESS: 'Éxito',
        ADD_SUCCESS: 'Usuario creado correctamente.',
        EDIT_SUCCESS: 'Usuario modificado correctamente.',
        EMAIL_EXISTS: 'Correo electrónico ya existentes.',
        CUSTOMIZE: 'Correo electrónico ya existentes.',
      },
      VALIDATION: {
        RFC: 'RFC es requerido',
        NAME: 'Nombre es requerido',
        EMAIL: 'Correo electrónico es requerido',
        WHATSAPP: 'Whatsapp es requerido',
        WEB_SERVICE_ID: 'Servicio web es requerido',
        ACTIVE_CHANGE_PASSWORD: 'Cambio activo de contraseña es requerido',
        BANNER1_ALIGN: 'Posición del banner individual es requerido',
        ACTIVE_BANNER1: 'Activar Banner 1 es requerido',
      },
      TITLE: 'Configuración de perfil',
      CHANGE_PASSWORD: 'Cambia la contraseña',
      VIEW: 'Ver Planes',
      APPLY: 'aplicar planes',
      RFC: 'RFC',
      NAME: 'Nombre completo o Razón social',
      FIRST_NAME: 'Nombre',
      LAST_NAME: 'Apellido Paterno',
      USERNAME: 'Nombre de usuario',
      MOTHER_LAST_NAME: 'Apellido Materno',
      EMAIL: 'Correo Electrónico',
      CURP: 'CURP',
      VERIFIED: 'Activado',
      WEB_SERVICE_ID: 'Seleccione Servicio web',
      CFDI_DOCUMENTS: 'Número de Documentos CFDI',
      PAYROLL_DOCUMENTS: 'Número de Documentos Nómina',
      API_DOCUMENTS: 'Número de Documentos API/BATCH',
      PAYMENT_DOCUMENTS: 'Número de Documentos Pagos',
      CFDI_USED_DOCUMENTS: 'Número Usado de Documentos CFDI',
      API_USED_DOCUMENTS: 'Número Usado de Documentos API/BATCH',
      PAYMENT_USED_DOCUMENTS: 'Número Usado de Documentos Pagos',
      PURCHASES_CFDI: 'Número de CFDI Comprado',
      PURCHASES_API: 'Número de API Comprado ',
      PURCHASES_PAYMENT: 'Número de Pagos Comprado ',
      DATE: 'Fecha',
      PLAN_ID: 'Plan',
      PRICE: 'Precio',
      PAYMENT_METHOD: 'M&eacute;todo de Pago',
      SELLER: 'Vendedor',
      NOTE: 'Nota',
      EXPIRY_DATE: 'Fecha de vencimiento de anualidad',
      MANIFEST: 'Manifiesto',
      ACTIVE_MANIFEST: 'Activa CMV',
      ACTIVE_PREMIUM: 'Activar premium reporte web',
      ACTIVE_PREMIUM_API: 'Activar premium reporte batch',
      ACTIVE_CFDI_PRIMARY: 'Activar de roles o CFDI Sector Primario',
      ACTIVE_DONEE: 'Activar donatarias',
      ACTIVE_DOWNLOAD_XML_API: 'Descargar xml antiguo',
      ACTIVE_DOWNLOAD_PDF_API: 'Descargar pdf antiguo',
      API_RESPONSE: 'Respuesta API',
      ACTIVE_MULTIPLE_PAYROLL: 'Activar nómina múltiple',
      ACTIVE_MULTIPLE_PAYROLL_BATCH: 'Activar nómina múltiple batch',
      BANNER: 'Banner',
      BANNER1: 'Banner 1',
      BANNER2: 'Banner 2',
      BANNER1_ALIGN: 'Posición del banner individual',
      BANNER2_ALIGN: 'Align',
      ACTIVE_BANNER1: 'Activar Banner 1',
      ACTIVE_BANNER2: 'Activar Banner 2',
      BOX1: 'Box 1',
      BOX1_NOTIFICATION: 'Box 1 notificación',
      BOX2: 'Box 1',
      BOX2_NOTIFICATION: 'Box 2 notificación',
      WHATSAPP: 'Whatsapp',
      ACTIVE_EXPIRY_NOTIFICATION: 'Activa anualidad por vencer',
      ACTIVE_CHANGE_PASSWORD: 'Cambio activo de contraseña',
      ACTIVE_INVOICE_WITH_PERCENT: 'Active Facturas con porcentaje',
      ACTIVE_INVOICE_WITH_PERCENT_BATCH: 'Active Facturas con porcentaje batch',
    },
    CHANGE_PASSWORD: {
      TITLE: 'Cambiar la contraseña de acceso al sistema',
      VALIDATION: {
        CURRENT_PASSWORD: 'Contraseña actual es requerido.',
        NEW_PASSWORD: 'Nueva contraseña es requerido.',
        RETYPE_PASSWORD: 'Confirme nueva contraseña es requerido.',
        NEW_CONFIRM_PASSWORD_ERR:
          'La nueva contraseña y la confirmación de la contraseña no son las mismas',
      },
      FIELDS: {
        CURRENT_PASSWORD: 'Contraseña actual',
        NEW_PASSWORD: 'Nueva contraseña',
        RETYPE_PASSWORD: 'Confirme nueva contraseña',
        PASSWORD_LENGTH: 'La contraseña debe tener mínimo 8 caracteres',
        PASSWORD_MUST_START_WITH:
          'Iniciar con una letra e incluir al  menos un número',
        PASSWORD_SPECIAL_CHARS: 'Se pueden usar los signos @$!%*#?',
        NO_SPACE_ALLOW: 'No usar espacios',
        INVALID_PASSWORD:
          'La nueva contraseña debe iniciar con una letra e incluir al menos un número. Debe utilizar caracteres especiales como @$!%*#?',
        NEW_CONFIRM_PASSWORD_ERR:
          'La nueva contraseña y la confirmación de la contraseña no son las mismas',
      },
      MESSAGE: {
        FAIL: 'La contraseña de acceso actual no es correcta. Por favor intenta de nuevo.',
        SUCCESS: 'Exito',
        MESSAGE: 'Contraseña modificada correctamente',
      },
    },
    CURRENCY: {
      HEADING: 'Administrar tipo de moneda',
      ADD: 'Añadir tipo de moneda',
      EDIT: 'Modificar tipo de moneda',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      SINGLE_NAME: 'Único Nombre (Español)',
      NAME: 'Nombre (Español)',
      SINGLE_NAME_EN: 'Único Nombre (Inglés)',
      NAME_EN: 'Nombre (Inglés)',
      CODE: 'C&oacute;digo',
      ENABLED: 'Habilitado',
      EXCHANGE_RATE: 'Tipo de cambio'
    },
    WEBSERVICE: {
      HEADING: 'Administrar servicio web',
      ADD: 'Añadir servicio web',
      EDIT: 'Modificar servicio web',
      NAME: 'Nombre',
      ACTIVE: 'Activo',
    },
    TAXMANAGER: {
      HEADING: 'Administrar impuestos tipo',
      ADD: 'Añadir impuestos tipo',
      EDIT: 'Modificar impuestos tipo',
    },
    PLAN: {
      HEADING: 'Administrar plan',
      ADD: 'Añadir plan',
      EDIT: 'Modificar plan',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      DOCUMENTCFDI: 'Documentos CFDI',
      DOCUMENTAPI: 'Documentos API/batch',
      DOCUMENTPRICE: 'Documents pago',
      PRICE: 'Precio',
      TYPE: 'Tipo',
      ACTIVEINACTIVE: 'Inactivo/Activo',
    },
    BANK: {
      HEADING: 'Banco',
      ADD: 'Añadir Banco',
      EDIT: 'Modificar Banco',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
      BANKRFC: 'Banco RFC',
    },
    COLONY: {
      HEADING: 'Colonia',
      ADD: 'Añadir colonia',
      EDIT: 'Modificar colonia',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
      POSTAL_CODE: 'Código postal',
    },
    COUNTRY: {
      HEADING: 'Registro de País',
      ADD: 'Añadir registro de País',
      EDIT: 'Modificar registro de País',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Factura Código',
      RETENTION_CODE: 'Retención Código',
    },
    STATE: {
      HEADING: 'Estado',
      ADD: 'Añadir estado',
      EDIT: 'Modificar estado',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
      COUNTRY_NAME: 'País',
    },
    LOCATION: {
      HEADING: 'Localidade',
      ADD: 'Añadir localidade',
      EDIT: 'Modificar localidade',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
      STATE: 'Estado',
    },
    MUNICIPALITY: {
      HEADING: 'Municipio',
      ADD: 'Añadir municipio',
      EDIT: 'Añadir municipio',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
      STATE: 'Estado',
    },
    POSTAL_CODE: {
      HEADING: 'Código postal',
      ADD: 'Añadir código postal',
      EDIT: 'Modificar código postal',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      CODE: 'Código',
      STATE: 'Estado',
      MUNICIPALITY: 'Municipio',
      LOCATION: 'Localidade',
    },
    TAX_OBJECT_CATEGORY: {
      HEADING: 'Catálogo objeto impuesto',
      ADD: 'Añadir catálogo objeto impuesto',
      EDIT: 'Modificar catálogo objeto impuesto',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    MONTH: {
      HEADING: 'Meses',
      ADD: 'Añadir meses',
      EDIT: 'Modificar meses',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    PERIODICITY: {
      HEADING: 'Periodicidad',
      ADD: 'Añadir periodicidad',
      EDIT: 'Modificar periodicidad',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    RETENTION_PAYMENT_TYPE: {
      HEADING: 'Tipo pago de la retención',
      ADD: 'Añadir tipo pago de la retención',
      EDIT: 'Modificar tipo pago de la retención',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    YEAR: {
      HEADING: 'Año',
      ADD: 'Añadir año',
      EDIT: 'Modificar año',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Año',
    },
    RELATIONSHIP_TYPE: {
      HEADING: 'Tipo de relación',
      ADD: 'Añadir tipo de relación',
      EDIT: 'Modificar tipo de relación',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    EXPORT: {
      HEADING: 'Exportación',
      ADD: 'Añadir exportación',
      EDIT: 'Modificar exportación',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    AGENT_NUMBER_TYPE: {
      HEADING: 'Número de autorización del agente',
      ADD: 'Añadir número de autorización del agente',
      EDIT: 'Modificar número de autorización del agente',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      START: 'Comenzar',
      END: 'Fin',
      CODE: 'Código',
    },
    LOAD_TYPE: {
      HEADING: 'Tipo de carga',
      ADD: 'Añadir tipo de carga',
      EDIT: 'Modificar tipo de carga',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    BOAT_TYPE: {
      HEADING: 'Tipo de embarcación',
      ADD: 'Añadir tipo de embarcación',
      EDIT: 'Modificar tipo de embarcación',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    AIR_TYPE: {
      ADD: 'Añadir código del transportista',
      EDIT: 'Modificar código del transportista',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    TRAILER_TYPE: {
      HEADING: 'Subtipo de remolque o semirremolque',
      ADD: 'Añadir subtipo de remolque o semirremolque',
      EDIT: 'Modificar subtipo de remolque o semirremolque',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    PERMISSION_TYPE: {
      HEADING: 'Tipo de permiso proporcionado por la SCT',
      ADD: 'Añadir tipo de permiso proporcionado por la SCT',
      EDIT: 'Modificar tipo de permiso proporcionado por la SCT',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    FEDERAL_TRUCKING_TYPE: {
      HEADING: 'Clave de nomenclatura del autotransporte',
      ADD: 'Añadir clave de nomenclatura del autotransporte',
      EDIT: 'Modificar clave de nomenclatura del autotransporte',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    PACKAGING_UNIT: {
      HEADING: 'Catálogo de unidades de medida y embalaje',
      ADD: 'Añadir catálogo de unidades de medida y embalaje',
      EDIT: 'Modificar catálogo de unidades de medida y embalaje',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    PACKAGING_TYPE: {
      HEADING: 'Catálogo de embalaje',
      ADD: 'Añadir catálogo de embalaje',
      EDIT: 'Modificar catálogo de embalaje',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    PRODUCT_STCC_CATEGORY: {
      HEADING: 'Catálogo de productos STCC',
      ADD: 'Añadir catálogo de productos STCC',
      EDIT: 'Modificar catálogo de productos STCC',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    HAZARDOUS_CATEGORY: {
      HEADING: 'Catálogo de materiales peligrosos',
      ADD: 'Añadir catálogo de materiales peligrosos',
      EDIT: 'Modificar catálogo de materiales peligrosos',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    STATION_LIST: {
      HEADING: 'Listas de estación',
      ADD: 'Añadir listas de estación',
      EDIT: 'Modificar listas de estación',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
      IDENTIFICATION_CODE: 'Clave identificación',
    },
    STATION: {
      HEADING: 'Llave de estación',
      ADD: 'Añadir llave de estación',
      EDIT: 'Modificar llave de estación',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    TRANSPORT: {
      HEADING: 'Llave de transporte',
      ADD: 'Añadir llave de transporte',
      EDIT: 'Modificar llave de transporte',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Nombre',
      CODE: 'Código',
    },
    MERCHANDISE_MEASURE: {
      HEADING: 'Unidad de medida aplicable a la mercancia',
      ADD: 'Añadir unidad de medida aplicable a la mercancia',
      EDIT: 'Modificar unidad de medida aplicable a la mercancia',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    TRANSFER_REASON: {
      HEADING: 'Motivo de traslado',
      ADD: 'Añadir motivo de traslado',
      EDIT: 'Modificar motivo de traslado',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    USE_CFDI: {
      HEADING: 'Uso CFDI',
      TITLE: 'Nombre',
      CODE: 'Código',
      STATE: 'Nombre',
      COUNTRY: 'Aplicable físicamente',
      STATUS: 'Aplicación moral',
      ADD: 'Añadir uso CFDI',
      EDIT: 'Modificar uso CFDI',
    },
    PRODUCT_CATALOG: {
      HEADING: 'Clave de producto o servicio',
      CODE: 'Código',
      TITLE: 'Nombre',
      START_DATE_VALIDITY: 'Validez de la fecha de inicio',
      END_DATE_VALIDITY: 'Validez de la fecha de finalización',
      INCLUDE_IVA: 'Incluye iva',
      INCLUDE_IEPS: 'Incluye IEPS',
      INCLUDE_COMPLEMENT: 'Incluye complemento',
      ADD: 'Añadir Clave de producto o servicio',
      EDIT: 'Modificar Clave de producto o servicio',
    },
    MEASUREMENT: {
      HEADING: 'Unidad de medida',
      CODE: 'Código',
      TITLE: 'Nombre',
      ENDDATE: 'Fetcha Fin',
      DISCRIPTION: 'Descripción',
      ADD: 'Añadir Unidad de medida',
      EDIT: 'Modificar Unidad de medida',
    },
    ORIGIN_BANK: {
      HEADING: 'Banco Origen & Destino',
      RFC: 'RFC',
      NAME: 'Nombre',
      BANK_ORIGIN: 'Origen bancario activo',
      BANK_DESTINATION: 'Destino del banco activo',
      ADD: 'Añadir Banco Origen & Destino',
      EDIT: 'Modificar Banco Origen & Destino',
    },
    TEMPLATE: {
      HEADING: 'Modelo',
      NAMEES: 'Nombre (Spanish)',
      NAMEEN: 'Nombre (English)',
      ADD: 'Añadir Modelo',
      EDIT: 'Modificar Modelo',
    },
    FISCAL_EXERCISE: {
      HEADING: 'Fiscal Exercise',
      ADD: 'Añadir Fiscal Exercise',
      EDIT: 'Modificar Fiscal Exercise',
      NAME: 'Nombre',
      CODE: 'Código',
      STATUS: 'Estado',
    },
    EDUCATION_LEVEL: {
      HEADING: 'Nivel educativo',
      ADD: 'Añadir Nivel educativo',
      EDIT: 'Modificar Nivel educativo',
      TITLE: 'Nombre',
      STATUS: 'Estado',
    },
    OPERATION: {
      HEADING: 'Operación',
      ADD: 'Añadir Operación',
      EDIT: 'Modificar Operación',
      TITLE: 'Nombre',
      CODE: 'Código',
      STATUS: 'Estado',
    },
    PETITION: {
      HEADING: 'Pedimento',
      ADD: 'Añadir Pedimento',
      EDIT: 'Modificar Pedimento',
      TITLE: 'Nombre',
      CODE: 'Código',
      STATUS: 'Estado',
    },
    INCOTERM: {
      HEADING: 'Incoterm',
      ADD: 'Añadir Incoterm',
      EDIT: 'Modificar Incoterm',
      TITLE: 'Nombre',
      CODE: 'Código',
      STATUS: 'Estado',
    },
    JOBTYPE: {
      HEADING: 'Puesto del Empleado',
      ADD: 'Añadir Puesto del Empleado',
      EDIT: 'Modificar Puesto del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EXTRAHOUR: {
      HEADING: 'Horas Extra del Empleado',
      ADD: 'Añadir Horas Extra del Empleado',
      EDIT: 'Modificar Horas Extra del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEPAYMENTPERIODTYPE: {
      HEADING: 'Periodicidad de pago del Empleado',
      ADD: 'Añadir Periodicidad de pago del Empleado',
      EDIT: 'Modificar Periodicidad de pago del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEJOBRISKTYPE: {
      HEADING: 'Riesgo de trabajo del Empleado',
      ADD: 'Añadir Riesgo de trabajo del Empleado',
      EDIT: 'Modificar Riesgo de trabajo del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEOFFICIALJOBTYPE: {
      HEADING: 'Puesto Oficial del Empleado',
      ADD: 'Añadir Puesto Oficial del Empleado',
      EDIT: 'Modificar Puesto Oficial del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEEXPENSETYPE: {
      HEADING: 'Percepciones del Empleado',
      ADD: 'Añadir Percepciones del Empleado',
      EDIT: 'Modificar Percepciones del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEECONTRACTTYPE: {
      HEADING: 'Tipo de contrato del Empleado',
      ADD: 'Añadir Tipo de contrato del Empleado',
      EDIT: 'Modificar Tipo de contrato del Empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEDISABILITYTYPE: {
      HEADING: 'Incapacidades del empleado',
      ADD: 'Añadir Incapacidades del empleado',
      EDIT: 'Modificar Incapacidades del empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEOTHERTYPE: {
      HEADING: 'Otro Tipo de Pago',
      ADD: 'Añadir Otro Tipo de Pago',
      EDIT: 'Modificar Otro Tipo de Pago',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    EMPLOYEEDISCOUNTTYPE: {
      HEADING: 'Deducciones del empleado',
      ADD: 'Añadir Deducciones del empleado',
      EDIT: 'Modificar Deducciones del empleado',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    RETENTIONKEY: {
      HEADING: 'Clave de Retención',
      ADD: 'Añadir Clave de Retención',
      EDIT: 'Modificar Clave de Retención',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    DISTRIBUTEDINCOMETYPE: {
      HEADING: 'Retención Tipo de ingreso distribuido',
      ADD: 'Añadir Retención Tipo de ingreso distribuido',
      EDIT: 'Modificar Retención Tipo de ingreso distribuido',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    FEDERALENTITY: {
      HEADING: 'Entidad Federal de Retención',
      ADD: 'Añadir Entidad Federal de Retención',
      EDIT: 'Modificar Entidad Federal de Retención',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    RETENTIONTAXTYPE: {
      HEADING: 'Tipo de Impuesto de Retención',
      ADD: 'Añadir Tipo de Impuesto de Retención',
      EDIT: 'Modificar Tipo de Impuesto de Retención',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    TAXPAYERSUBJECTTYPE: {
      HEADING: 'Tipo de sujeto del contribuyente de retención',
      ADD: 'Añadir Tipo de sujeto del contribuyente de retención',
      EDIT: 'Modificar Tipo de sujeto del contribuyente de retención',
      NAME: 'Nombre',
      CODE: 'Código',
    },
    MASTER: {
      HEADING: 'Usuarios',
      HEADINGEXPIRED: 'Usuarios vencidos',
      FIRSTNAME: 'Nombre',
      RFC: 'RFC',
      USERNAME: 'Nombre de usuario',
      NOOFCFDIDOCUMENTS: 'Número de Documentos CFDI',
      NOOFAPIDOCUMENTS: 'Número de Documentos API/BATCH',
      NOOFPAYMENTDOCUMENTS: 'Número de Documentos Pagos',
      COUPON: 'Cupón',
      VERIFIED: 'Activado',
      EXPIRATIONDATE: 'Fecha de vencimiento de anualidad',
      NOOFPURCHASEDCFDI: 'Número de CFDI Comprado',
      NOOFPURCHASESAPI: 'Número de API Comprado',
      NOOFPURCHASESPAYMENT: 'Número de Pagos Comprado',
      NOOFUSEDCFDIDOCUMENTS: 'Número Usado de Documentos CFDI',
      NOOFUSEDAPIDOCUMENTS: 'Número Usado de Documentos API/BATCH',
      NOOFUSEDPAYMENTDOCUMENTS: 'Número Usado de Documentos Pagos',
      EDIT: 'Editar',
      DELETE: 'Borrar',
      VIEWPLANS: 'Ver planes',
      VIEWPLANHEADING: 'Ver planes',
      APPLYPLANS: 'Aplicar planes',
      CHANGEPASSWORD: 'Cambia la contraseña ',
      CHANGEPASSWORDBTN: 'Cambia la contraseña',
      SENDEMAIL: 'Enviar correo',
      ADDPURCHASEPLAN: 'Historial de Planes adquiridos',
      SELECTPLAN: 'Seleccione Plan',
      NEWPASSWORD: 'Nueva contraseña',
      CONFIRMPASSWORD: 'Confirme nueva contraseña',
      PASSWORDLENGTH: 'La contraseña debe contener al menos 8 caracteres',
      PASSWORDALPHA:
        'La contraseña debe comenzar con A_Z o a_z y contener al menos un número',
      USERSPEACIALCHAR:
        'El usuario puede usar caracteres especiales como @$!%*#?&',
      MESSAGE: {
        ADD_SUCCESS: 'Usuario creado correctamente',
        EDIT_SUCCESS: 'Usuario modificado correctamente',
        EMAIL_EXISTS: 'Correo electrónico ya existentes',
        CUSTOMIZE: 'Correo electrónico ya existentes',
      },
    },
    MASTER_FIELD: {
      CREATE: 'Crear',
      EDIT: 'Actualizar',
      USER_CREATE: 'Crear usuario principal.',
      USER_EDIT: 'Modificar usuario principal',
      YES: 'Sí',
      NO: 'No',
      XML: 'XML',
      XMLPDF: 'XML & PDF',
      CHANGE_PASSWORD: 'Cambia la contraseña',
      VIEW: 'Ver Planes',
      APPLY: 'aplicar planes',
      RFC: 'RFC',
      NAME: 'Nombre completo o Razón social',
      FIRST_NAME: 'Nombre',
      LAST_NAME: 'Apellido Paterno',
      USERNAME: 'Nombre de usuario',
      MOTHER_LAST_NAME: 'Apellido Materno',
      EMAIL_ADDRESS: 'Correo Electrónico',
      SELECTPAYMENT: 'Select Payment Method',
      CURP: 'CURP',
      VERIFIED: 'Activado',
      WEB_SERVICE_ID: 'Seleccione Servicio Web',
      CFDI_DOCUMENTS: 'Número de Documentos CFDI',
      PAYROLL_DOCUMENTS: 'Número de Documentos Nómina',
      API_DOCUMENTS: 'Número de Documentos API/BATCH',
      PAYMENT_DOCUMENTS: 'Número de Documentos Pagos',
      CFDI_USED_DOCUMENTS: 'Número Usado de Documentos CFDI',
      API_USED_DOCUMENTS: 'Número Usado de Documentos API/BATCH',
      PAYMENT_USED_DOCUMENTS: 'Número Usado de Documentos Pagos',
      PURCHASES_CFDI: 'Número de CFDI Comprado',
      PURCHASES_API: 'Número de API Comprado ',
      PURCHASES_PAYMENT: 'Número de Pagos Comprado ',
      DATE: 'Fecha',
      PLAN_ID: 'Plan',
      PRICE: 'Precio',
      PAYMENT_METHOD: 'M&eacute;todo de Pago',
      SELLER: 'Vendedor',
      NOTE: 'Nota',
      EXPIRY_DATE: 'Fecha de vencimiento de anualidad',
      MANIFEST: 'Manifiesto',
      ACTIVE_MANIFEST: 'Activa CMV',
      ACTIVE_PREMIUM: 'Activar premium reporte web',
      ACTIVE_PREMIUM_API: 'Activar premium reporte batch',
      ACTIVE_CFDI_PRIMARY: 'Activar de roles o CFDI Sector Primario',
      ACTIVE_DONEE: 'Activar donatarias',
      ACTIVE_DOWNLOAD_XML_API: 'Descargar xml antiguo',
      ACTIVE_DOWNLOAD_PDF_API: 'Descargar pdf antiguo',
      API_RESPONSE: 'Respuesta API',
      ACTIVE_MULTIPLE_PAYROLL: 'Activar nómina múltiple',
      ACTIVE_MULTIPLE_PAYROLL_BATCH: 'Activar nómina múltiple batch',
      BANNER: 'Banner',
      BANNER1: 'Banner 1',
      BANNER2: 'Banner 2',
      BANNER1_ALIGN: 'Posición del banner individual',
      BANNER2_ALIGN: 'Align',
      ACTIVE_BANNER1: 'Activar Banner 1',
      ACTIVE_BANNER2: 'Activar Banner 2',
      BOX1: 'Box 1',
      BOX1_NOTIFICATION: 'Box 1 notificación',
      BOX2: 'Box 1',
      BOX2_NOTIFICATION: 'Box 2 notificación',
      WHATSAPP: 'Whatsapp',
      ACTIVE_EXPIRY_NOTIFICATION: 'Activar anualidad por vencer',
      ACTIVE_CHANGE_PASSWORD: 'Cambio activo de contraseña',
      ACTIVE_INVOICE_WITH_PERCENT: 'Activar Facturas con porcentaje',
      ACTIVE_INVOICE_WITH_PERCENT_BATCH: 'Activar Facturas con porcentaje batch',
      ACTIVATE_INVOICE: 'Activar sección CFDI',
      ACTIVATE_PAYMENT: 'Activar CFDI Pagos',
      PAYROLL_ACTIVATE: 'Activar sección Nómina',
      RETENTIONSACTIVATE: 'Activar sección Retenciones',
      NOTARYACTIVATE: 'Activar sección Notarios',
      ACTIVATEEDUCATIONALSERIECFDI: 'Activar sección Inst Educativas',
      ACTIVATEUSEDCARSSERIECFDI: 'Activar sección Autos usados',
      ACTIVATEINESERIECFDI: 'Activar sección INE',
      ACTIVATECONSTRUCTIONPARTITIONCFDI: 'Activar sección Servicios Parciales de Construcción',
      ACTIVARFOREIGNTRADECFDI: 'Activar sección Comercio Exterior 2.0',
      ACTIVATEPREMIUMWEBREPORT: 'Activar premium reporte web',
      ACTIVATEPREMIUMBATCHREPORT: 'Activar premium reporte batch',
      ACTIVATEPRIMARYCFDISECTOR: 'Activar de roles o CFDI Sector Primario',
      ACTIVATEDONEE: 'Activar donatarias',
      ACTIVATECARTAPORTE: 'Activar Carta Porte 3.1',
      DOWNLOADOLDXML: 'Descargar xml antiguo',
      DOWNLOADOLDPDF: 'Descargar pdf antiguo',
      ACTIVATECREDITNOTESSECTION: 'Activar sección notas de crédito',
      APIRESPONSE: 'Respuesta de la API',
      ACTIVATEMULTIPLEPAYROLL: 'Activar nómina múltiple',
      ACTIVATEMULTIPLEPAYROLLBATCH: 'Activar nómina múltiple batch',
      ACTIVATEINVOICESWITHPERCENTAGE: 'Activar Facturas con porcentaje',
      ACTIVATEINVOICESWITHPERCENTAGEBATCH:
        'Activar Facturas con porcentaje batch',
    },
    COMPANY: {
      TITLE: 'Empresas',
      ADD_TITLE: 'Crear empresa',
      EDIT_TITLE: 'Modificar Empresa',
      ADD_MORE: 'Añadir m&aacute;s registros',
      MANAGE_TITLE: 'Administrar',
      NEW: 'Nueva',
      EDIT: 'Editar',
      DELETE: 'Borrar',
      ERROR: {
        INTERNAL_NAME: 'Nombre de la empresa es requerido.',
        NAME: 'Nombre de la empresa es requerido.',
        POSTAL_CODE_ID: 'Código Postal es requerido',
        COLONY: 'Campo Colonia es requerido',
        LOCATION: 'Campo Localidad es requerido',
        DELEGATION_OR_MUNICIPALITY: 'Delegación o Municipio es requerido',
        STATE: 'Estado es requerido',
        BOARD_TYPE: 'Tipo de actividad fiscal es requerido',
      },
      FIELDS: {
        SELECT_FILE: 'Seleccione un archivo',
        IMAGE_FILE:
          'El nombre del archivo no debe tener espacios. Tipo de imagen admitido: JPG, PNG. Tamaño máximo de 800 Kb',
        BATCH_FILE: 'El nombre del archivo no debe tener espacios. Tipo admitido: txt, xml.',
        SUGGESTED_LABEL: 'Destacado',
        UPLOAD_LABEL: 'Subir imagen',
        UPLOAD: 'Seleccione un archivo',
        CANCEL_UPLOAD: 'Cancelar la carga',
        INTERNAL_NAME: 'Nombre interno',
        NAME: 'Nombre de la empresa',
        BUSINESS_NAME: 'Razón social',
        CURP: 'Curp',
        STREET: 'Calle',
        EXTERIOR_NUMBER: 'Número Exterior',
        INTERIOR_NUMBER: 'Número Interior',
        COLONY: 'Colonia',
        ADDRESS: 'nombre nuevo domicilio',
        LOCATION: 'Localidad',
        ADDRESS_REFERENCE: 'Referencia',
        DELEGATION: 'Delegación o Municipio',
        MAYER_OR_MUNICIPALITY: 'Alcaldía o municipio',
        COUNTRY: 'País',
        STATE: 'Estado',
        OTHER_STATE: 'Otro estado',
        POSTAL_CODE: 'Código postal',
        TELEPHONE: 'Teléfono',
        EMAIL_ADDRESS: 'Correo Electrónico',
        WEBSITE: 'Página Web',
        USE_BRANCH: 'Dirección de sucursal',
        BRANCH_LABEL: '¿Utilizará una dirección de sucursal?',
        ACTIVE: 'Activo',
        AUTOMATIC_MAIL: 'Envío automático a Correo Electrónico',
        LOGO: 'Logotipo',
        BOARD_TYPE: 'Tipo de actividad fiscal',
        ACTIVATE_INVOICE: 'Activar sección CFDI',
        ACTIVATE_PAYMENT: 'Activar CFDI Pagos',
        ACTIVATE_PAYROLL: 'Activar sección Nómina',
        ACTIVATE_NOTARY: 'Activar sección Notarios',
        ACTIVATE_RETENTIONS: 'Activar sección Retenciones',
        INVOICE_FORMAT: 'Seleccione formato de Facturas',
        INVOICE_SERIES: 'Serie CFDI',
        INVOICE_START: 'De',
        INVOICE_END: 'Hasta',
        PAYROLL_SERIES: 'Serie Nómina',
        PAYROLL_START: 'De',
        PAYROLL_END: 'Hasta',
        RETENTIONS_SERIES: 'Serie Retenciones',
        RETENTIONS_START: 'De',
        RETENTIONS_END: 'Hasta',
        NOTARY_CURP: 'CURP del Notario',
        NOTARY_NUMBER: 'Número de Notar&iacute;a',
        HTML_TEMPLATE: 'Dise&ntilde;os PDF',
        ENABLED: 'Estado',
        ACTIVATE_EDUCATION: 'Activar sección Inst Educativas',
        ACTIVATE_USED_CARS: 'Activar sección Autos usados',
        ACTIVATE_INE: 'Activar sección INE',
        ACTIVATE_CONSTRUCTION_PARTITION:
          'Activar sección Servicios Parciales de Construcción',
        ACTIVATE_FOREIGN_TRADE: 'Activar sección Comercio Exterior',
        ACTIVATE_DONEE: 'Activar Donatarias',
        FEDERAL_ENTITY: 'Entidad Federativa',
        AFFILIATION: 'Adscripción',
        SEE_DESIGN: 'Ver Diseño',
        IS_ENGLISH: 'Generar PDF en inglés para este tipo de documento',
        ACTIVATE_CARRY_LETTER: 'Activar sección Carta Porte 1.0',
        ACTIVATE_CARTA_PORTE2: 'Activar sección Carta Porte 2.0',
        ACTIVATE_CARTA_PORTE3: 'Activar sección Carta Porte 3.1',
        ACTIVATE_CREDIT_NOTE: 'Activar sección notas de crédito',
        INVOICE_TRANSACTION_NUMBER:
          'Número de operación de Factura a través del adquiriente',
        PAYMENT_TRANSFER: 'Transferencia de pago',
        ACTIVATE_FOREIGN_TRADE_2: 'Activar sección Comercio Exterior 2.0',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro guardado correctamente',
        EDIT_SUCCESS: 'Información modificada correctamente',
      },
    },
    INVOICE_3: {
      INVOICE: 'Gestión de Facturas 3.3',
      LATEST_INVOICE: 'Gestión de Facturas CMV 3.3',
      LATEST_INVOICE_DRAFT: 'Borradores Facturas CMV 3.3',
      INVOICE_BATCH: 'Facturas Batch',
      INVOICE_BATCH_MODULE: 'Factura Batch',
      INVOICE_DRAFT: 'Borradores Facturas 3.3',
      CANCELLED_INVOICE: 'Cancelaciones Facturas 3.3',
      CANCELLED_LATEST_INVOICE: 'Cancelaciones Facturas CMV 3.3',
      FAILED_INVOICE: 'Intentos Fallidos Facturas 3.3',
      FAILED_LATEST_INVOICE: 'Fallidos Facturas CMV 3.3',
      CANCELLED_INVOICE_BATCH: 'Cancelaciones Batch 3.3',
      FAILED_INVOICE_BATCH: 'Intentos Fallidos Batch 3.3',
      BULK_INVOICE: 'Descarga Facturas Web',
      BULK_INVOICE_BATCH: 'Descarga Facturas Batch',
      ADDENDA: 'Administración de Addenda',
      INVOICE_REPORT: 'Reporte Facturas Web',
      INVOICE_BATCH_REPORT: 'Reporte Facturas Batch',
      INVOICE_PAYMENT_REPORT: 'Reporte Recibo Electrónico de Pago Web',
      INVOICE_PAYMENT_BATCH_REPORT: 'Reporte Recibo Electrónico de Pago Batch',
      INVOICE_PAYMENT_MENU: 'Reporte Factura Pagos Web',
      INVOICE_PREMIUM_MENU: 'Reporte Plus Pagos Web',
      INVOICE_PREMIUM_BATCH_MENU: 'Reporte Plus Pagos Batch',
      INVOICE_PAYMENT_BATCH_MENU: 'Reporte Factura Pagos Batch',
      NEW_INVOICE: 'Nueva Factura',
      NEW_TAX_INVOICE: 'Nuevo Batch múltiples impuestos',
      NEW_BATCH: 'Nueva Factura Batch',
      NEW_STATIC_BATCH: 'Nuevo Batch Importes manuales',
      GET_CFDI_RESPONSE: 'Estatus del CFDI',
      TITLE: 'Factura',
      ADD_TITLE: 'Crear Factura',
      EDIT_TITLE: 'Modificar Factura',
      DELETE_TITLE: 'Borrar Factura',
      CANCEL_CONFIRMATION: 'Estatus del CFDI',
      PAID_TITLE: 'Pagada Factura',
      MANAGE_TITLE: 'Administrar',
      DRAFT_TITLE: 'Borradores Factura',
    },
    INVOICE_PERCENT: {
      INVOICE: 'Gestión de Facturas IEPS 4.0',
      INVOICE_DRAFT: 'Borradores Facturas IEPS 4.0',
      CANCELLED_INVOICE: 'Cancelaciones Facturas IEPS 4.0',
      FAILED_INVOICE: 'Intentos Fallidos Facturas IEPS 4.0',
    },
    INVOICE: {
      LABEL: {
        DRAFT: 'Borrador',
        ISSUE_PAYROLL: 'Emitir Recibo',
        ISSUE_CFDI: 'Emitir CFDI',
        EDUCATION: 'Complemento instituciones educativas',
        INE: 'Complemento INE',
        USED_CAR: 'Complemento Autos Usados',
        CONSTRUCTION: 'Complemento Servicios Parciales de Construcción',
        NOTARY: 'Complemento notarios',
        FOREIGN: 'Complemento comercio exterior',
        DONEE: 'Complemento de Donatarias',
        CARRY_LETTER: 'Complemento Carta Porte',
        CARRY_LETTER3: 'Complemento Carta Porte 3.1',
        COMMERCIAL_COMPLEMENT_2: 'Complemento comercio exterior 2.0',
        CONCEPT: 'Conceptos',
        SUBDIVISION_NO: 'No tiene subdivisión',
        SUBDIVISION_YES: 'Si tiene subdivisión',
        CERTIFICATE_ORIGIN_NO: 'No Funge como certificado de origen',
        CERTIFICATE_ORIGIN_YES: 'Funge como certificado de origen',
        ORDINARY: 'Ordinario',
        PRE_CAMPAIGN: 'Precampaña',
        CAMPAIGN: 'Campaña',
        NATIONAL_EXECUTIVE: 'Ejecutivo Nacional',
        STATE_EXECUTIVE: 'Ejecutivo Estatal',
        STATE_MANAGER: 'Directivo Estatal',
        LOCAL: 'Local',
        FEDERAL: 'Federal',
        STATE: 'Estado',
        MATTER_TYPE_ID: 'Tipo de materia del producto',
        MATTER_DETAILS: 'Descripción de la matería',
      },
      RELATED_SERIES: 'Serie del CFDI relacionado',
      RELATED_FOLIO: 'Folio del CFDI relacionado',
      RELATED_EXCHANGE_RATE: 'Tipo de cambio o equivalencia',
      ITMO_REGISTRATION: 'Registro ISTMO Tehuantepec',
      ITMO_REGISTRATION_ORIGIN: 'Ubicación del polo origen',
      ITMO_REGISTRATION_DESTINATION: 'Ubicación del polo destino',
      INGREDIENT_NAME: 'Nombre del ingrediente activo',
      CHEMICAL_NAME: 'Nombre químico',
      ACTIVE_MEDICINE_SUBSTANCE: 'Sustancia activa del medicamento',
      MEDICINE_BRAND: 'Marca con la que se promociona el medicamento',
      MEDICINE_MANUFACTURER: 'Fabricante del medicamento',
      MEDICINE_EXPIRATION_DATE: 'Fecha de caducidad del medicamento',
      MEDICATION_LOT_NUMBER: 'Número de Lote del medicamento',
      PHARMACEUTICAL_FORM: 'Forma farmaceútica del medicamento',
      MEDICINE_TRANSFER_CONDITION_ID: 'Condiciones especiales del traslado del medicamento',
      TRANSPORT_TRANSFER_CONDITION_ID: 'Condiciones especiales del traslado',
      COMPANY_HEALTH_RECORD: 'Registro sanitario de la empresa transportista',
      IMPORT_MEDICINE_PERMIT: 'Permiso de importación del medicamento',
      VUCEM_IMPORT_FOLIO: 'Folio de importación VUCEM',
      CAS_CHEMICAL_COMPOUND_NUMBER: 'Número de compuesto químico CAS',
      TOXIC_SUBSTANCE: 'Nombre del importador de sustancias tóxicas',
      HEALTH_REGISTRATION_NUMBER: 'Numero de registro sanitario CICLOPLAFEST',
      MANUFACTURER_NAME: 'País y nombre del fabricante del ingrediente activo del plaguicida o fertilizante',
      INGREDIENT_ACTIVE_NAME: 'País y nombre del formulador del ingrediente activo del plaguicida o fertilizante',
      INGREDIENT_MAQUILADORA_COUNTRY_NAME: 'País y nombre del maquilador del ingrediente activo del plaguicida o fertilizante',
      PESTICIDE_AUTHORIZED: 'Uso autorizado del plaguicida o fertilizante',
      RETURN_MERCHANDISE: '¿Es una recolección para devolución de mercancías?',
      CUSTOM_REGIMES: 'Regímenes Aduaneros',
      ADD_CUSTOM_REGIME: 'Agregar Régimen',
      CUSTOM_REGIME_ID: 'Régimen aduanero',
      SECTOR_HEADING: 'Sector COFEPRIS',
      SECTOR_ID: 'Clave de sector COFEPRIS',
      CUSTOMS_DOCUMENTATION: 'Documentación Aduanera',
      CUSTOM_DOCUMENT_ID: 'Tipo de documento aduanero',
      DOCUMENT_REQUIREMENT_NUMBER: 'Número de Pedimento',
      DOCUMENT_FOLIO: 'Folio del documento aduanero',
      DOCUMENT_RFC: 'RFC del importador de las mercancías',
      DOCUMENT_ADD: 'Agregar documento aduanero',
      GROSS_VEHICLE_WEIGHT: 'Peso bruto del vehículo',
      USED_CAR_LEVEL: 'NIV',
      FREE_TEXT_PLACEHOLDER: 'Ingrese su descripción',
      CHECK_THIRD_PARTY: 'A cuenta de terceros',
      THIRD_PARTY_RFC: 'RFC a cuenta de terceros',
      THIRD_PARTY_ACCOUNT_NAME: 'Nombre a cuenta de terceros',
      THIRD_PARTY_TAX: 'Régimen Fiscal a cuenta de terceros',
      THIRD_PARTY_TAX_RESIDENCE: 'Domiciio fiscal a cuenta de terceros',
      EXPORT: 'Exportación',
      GLOBAL_INFORMATION: 'Información Global',
      PERIODICITY_ID: 'Periodicidad',
      MONTH_ID: 'Mes',
      YEAR: 'Año',
      SEND: 'Enviar',
      INVOICE: 'Gestión de Facturas 4.0',
      LATEST_INVOICE: 'Gestión de Facturas CMV 4.0',
      LATEST_INVOICE_DRAFT: 'Borradores Facturas CMV 4.0',
      INVOICE_BATCH: 'Facturas Batch',
      INVOICE_BATCH_ADD: 'Añadir Nueva Factura Batch 4.0',
      INVOICE_BATCH_IEPS_ADD: 'Añadir Nueva Factura IEPS Batch 4.0',
      INVOICE_BATCH_MODULE: 'Factura Batch',
      INVOICE_DRAFT: 'Borradores Facturas 4.0',
      CANCELLED_INVOICE: 'Cancelaciones Facturas 4.0',
      CANCELLED_LATEST_INVOICE: 'Cancelaciones Facturas CMV 4.0',
      FAILED_INVOICE: 'Intentos Fallidos Facturas 4.0',
      FAILED_LATEST_INVOICE: 'Fallidos Facturas CMV 4.0',
      CANCELLED_INVOICE_BATCH: 'Cancelaciones Batch',
      FAILED_INVOICE_BATCH: 'Intentos Fallidos Batch',
      BULK_INVOICE: 'Descarga Facturas Web',
      BULK_INVOICE_BATCH: 'Descarga Facturas Batch',
      ADDENDA: 'Administración de Addenda',
      INVOICE_REPORT: 'Reporte Facturas Web',
      INVOICE_BATCH_REPORT: 'Reporte Facturas Batch',
      INVOICE_PAYMENT_REPORT: 'Reporte Recibo Electrónico de Pago Web',
      INVOICE_PAYMENT_BATCH_REPORT: 'Reporte Recibo Electrónico de Pago Batch',
      INVOICE_PAYMENT_MENU: 'Reporte Factura Pagos Web',
      INVOICE_PREMIUM_MENU: 'Reporte Plus Pagos Web',
      INVOICE_PREMIUM_BATCH_MENU: 'Reporte Plus Pagos Batch',
      INVOICE_PAYMENT_BATCH_MENU: 'Reporte Factura Pagos Batch',
      NEW_INVOICE: 'Nueva Factura',
      NEW_TAX_INVOICE: 'Nuevo Batch múltiples impuestos',
      NEW_BATCH: 'Nueva Factura Batch',
      NEW_STATIC_BATCH: 'Nuevo Batch Importes manuales',
      GET_CFDI_RESPONSE: 'Estatus del CFDI',
      TITLE: 'Factura',
      ADD_TITLE: 'Crear Factura 4.0',
      EDIT_TITLE: 'Modificar Factura 4.0',
      DELETE_TITLE: 'Borrar Factura',
      CANCEL_CONFIRMATION: 'Estatus del CFDI',
      PAID_TITLE: 'Pagada Factura',
      MANAGE_TITLE: 'Administrar',
      DRAFT_TITLE: 'Borradores Factura',
      DOCUMENT_TYPE: 'Tipo de documento',
      DOCUMENT_TYPE_ERROR: 'Tipo de documento es requerida',
      FILE_TYPE: 'Tipo de archivo',
      FILE_TYPE_ERROR: 'Tipo de archivo es requerida',
      FILE: 'Archivo',
      CHOSE_FILE: 'Por favor seleccione su archivo para cargarlo al sistema',
      FILE_ERROR: 'El archivo es requerido',
      FILE_TYPE_TEXT: 'El archivo debe ser texto/sin formato',
      FILE_TYPE_XML: 'El archivo debe ser xml.',
      STATUS: 'Estado',
      SERIES_FOLIO: 'Serie / Folio',
      EDIT_TAX_MESSAGE: 'El impuesto Exento no es editable',
      RECEPTOR_RFC: 'RFC del receptor',
      RECEIVER_NAME: 'Nombre del receptor',
      INVOICE_PAYMENT_DATE: 'Fecha de Pago',
      INVOICE_PAID: 'Pagada',
      COMPANY_RFC: 'RFC emisor',
      TRADE_NAME: 'Nombre comercial',
      INVOICE_CREATION_DATE: 'Fecha de Certificacion',
      TYPE_OF_VOUCHER: 'Tipo de comprobante',
      CERTIFICATE: 'Certificador',
      INVOICE_CONFIRMATION_CODE: 'Clave de confirmacion',
      INVOICE_RELATION_TYPE: 'Tipo de relación',
      UUID_RELATED: 'UUID relacionado',
      INVOICE_PAYMENT: 'Método de Pago',
      INVOICE_PAYMENT_WAY: 'Forma de pago',
      INVOICE_STATE: 'Estatus del CFDI',
      GRID_PLACE: 'Lugar de Expedición',
      GRID_CANCELLED_DATE: 'Fecha de cancelacion',
      BY_USER: 'Creado por usuario',
      PRODUCT_ID: 'Nombre de producto',
      QUANTITY: 'Cantidad',
      UNIT_OF_MEASUREMENT: 'Clave unidad',
      UNIT_PRICE: 'Valor Unitario',
      IEPS_PERCENTAGE: 'Descuento',
      DESCRIPTION: 'Descripcion',
      ADD_PRODUCT: 'Agregar Concepto',
      EDIT_PRODUCT: 'Actualizar Concepto',
      ADD_INFORMATION: 'Agregar',
      NUMBER: 'Número',
      DATE: 'Fecha',
      ADUANA: 'Aduana',
      PRODUCT_CODE: 'Clave de producto/servicio',
      CUSTOM_VAT: 'Identification No',
      BRANCH_ADDRESS:
        '¿Usará una dirección de expedición diferente a su dirección fiscal?',
      DISCOUNT: 'Descuento',
      REQUIREMENT_NUMBER: 'Numero de pedimento',
      INFORMATION_ADDITION: 'Información adicional',
      PROPERTY_ACCOUNT: 'Cuenta predial',
      ADD_PROPERTY_ACCOUNT: 'Agregar Cuenta predial',
      ADD_PART: 'Agregar registro parte',
      EDIT_TOTAL: 'Cambiar los valores',
      TAXES: 'Impuestos',
      DISCOUNT_HEADING: 'Agregar Descuento (opcional)',
      DESCUENTO: 'Descuento',
      DISCOUNT_REASON: 'Motivo de Descuento',
      TAX_MOVE: 'Impuestos Trasladados (opcional)',
      RETENTION_IAV: 'Retenciones IVA (opcional)',
      RETENTION_ISR: 'Retenciones ISR (opcional)',
      WITHHOLDING_TAX: 'Impuesto Cedular Retenido (opcional)',
      OTHER_TAX: 'Otros Impuestos (opcional)',
      TAX_CEDULAR_TRANSER: 'Impuestos Cedular Traslados(opcional)',
      PAY: 'Pagaré',
      INCLUDE_NOTE: ' Incluir texto pagaré en su factura',
      ADDENDA_HEADING: 'Seleccione Addenda',
      SUB_TOTAL: 'Subtotal',
      IEPS: 'IEPS',
      TOTAL_TAX_MOVE: 'Total Impuestos Trasladados',
      TOTAL_TAX_WITHHOLD: 'Total Impuestos Retenidos',
      TOTAL: 'Total',
      PAYMENT_CONDITION: 'Condiciones de pago',
      CONFIRMATION_CODE: 'Clave de confirmacion',
      CFDI_RELATED: 'Cfdi Relacionados',
      TYPE_OF_RELATIONSHIP: 'Tipo de relación',
      RELATED_UUID: 'UUID relacionado',
      AMOUNT: 'Importe',
      ALL_PART_OF_RELEATED_PRODUCT:
        'Elija el registro de Parte para este concepto (Opcional)',
      TRANSFER: 'Impuestos Trasladados',
      RETENTION: 'Impuesto Retenido',
      OTHER_TRANSFER: 'Otros Impuestos Trasladados',
      OTHER_RETENTION: 'Otros Impuestos Retenidos',
      OTHER_TOTAL_TAX_MOVE: 'Total Impuestos Otro Trasladados',
      OTHER_TOTAL_TAX_WITHHOLD: 'Total Impuestos Otro Retenidos',
      BASE: 'Base',
      INVOICE_TAX_TYPE: 'Impuesto',
      FACTOR_TYPE: 'Tipo de factor',
      SHARE_FEES: 'Tasa cuota',
      ADD_TAXES: 'Agregar Impuestos',
      MODIFY_TAXES: 'Modificar impuesto',
      ADD1: 'Agregar',
      RECEIVER_DATA: 'Datos Del Cliente',
      CUSTOMER: 'Nombre Del Cliente',
      CUSTOMER_ON_FILTER: 'Nombre del receptor',
      MAIN_DISCOUNT: 'Agregar Descuento(opcional)',
      ADD: 'Añadir',
      USE_FREE_TEXT: 'Usar área de texto libre',
      TAX_NAME1: 'Nombre del impuesto',
      PERCENTAGE: 'Porcentaje',
      PAYMENT_HEADING: 'Datos Generales Del Pago',
      PAYMENT_AMOUNT: 'Importe del Pago',
      OPERATION_NUMBER: 'Número de Operación',
      RFC_ORIGIN_ENTITY: 'RFC del Banco Origen',
      BANK_NAME: 'Nombre del banco',
      ORDER_ACCOUNT: 'Cuenta ordenante',
      DEPOSITE_INFO: 'Información del depósito (opcional)',
      RFC_ENTITY_DESTINATION: 'RFC del Banco Destino',
      RFC_ENTITY_NAME: 'Nombre del banco Receptor',
      RECEIVE_ACCOUNT: 'Cuenta donde se recibe el pago',
      PAYMENT_TYPE: 'Tipo de cadena de pago',
      PAYMENT_CERTIFICATE: 'Certificado de pago',
      ORIGINAL_PAYMENT: 'Cadena original de pago',
      DIGITAL_PAYMENT_STAMP: 'Sello digital del pago',
      ADD_PAYMENT: 'Agregar Pago',
      MODIFY_PAYMENT: 'Modificar Pago',
      APP_PAYMENT_DETAIL: 'Agregar CFDI Relacionados',
      MODIFY_PAYMENT_DETAIL: 'Modificar CFDI Relacionados',
      ADD_RELATED_DATA: 'Agregar CFDI relacionado',
      RALATED_CFDI_INFO: 'Datos de CFDI relacionados',
      CFDI_TEXT: 'Folio fiscal del CFDI relacionado',
      SERIE: 'Serie del CFDI origen',
      FOLIO: 'Folio del CFDI origen',
      CURRENCY: 'Moneda',
      PARTIAL_NUMBER: 'Número de parcialidad',
      PREVIOUS_AMOUNT: 'Importe saldo anterior',
      PAID_AMOUNT: 'Importe pagado',
      UNPAID_AMOUNT: 'Importe insoluto',
      PAYMENT_CUSTOMER:
        'Para usar ésta opción es necesario agregar registros del mismo cliente',
      PAYMENT_COMPANY:
        'Para usar ésta opción es necesario agregar registros de la misma sucursal o empresa',
      REQUIRED_FIELDS: 'Rellene los campos obligatorios',
      STUDENT_NAME: 'Nombre del Alumno',
      CURP: 'CURP',
      LEVEL: 'Nivel Educativo',
      AUTHORIZATION: 'Autorización Oficial',
      RFC_PAYMENT: 'RFC De Pago',
      COMPLEMENT: 'Complementos',
      COMPLEMENT_CONCEPT: 'Complementos Concepto',
      USE_CFDI_COUNTER: 'Usar la misma serie y folio de sección CFDI',
      SELECT_COMPLEMENT_TYPE: 'Seleccione Uno',
      PROCESS_TYPE: 'Tipo de Proceso',
      COMMITTEE_TYPE: 'Tipo de Comité',
      NATIONAL_ACCOUNTING_ID:
        'Clave de Contabilidad para proceso ordinario y Comité Ejecutivo Nacional',
      INTERNATIONAL_ACCOUNTING_ID:
        'Clave de Contabilidad para proceso ordinario y Comité Ejecutivo Estatal',
      STATE: 'Clave de entidad',
      SCOPE: 'Ambito',
      INE_COMPLEMENT: 'Complemento INE',
      SCHOOL_COMPLEMENT: 'Complemento instituciones educativas',
      AMOUNT_ACQUISITION: 'Monto Adquisición',
      ALIENATION_AMOUNT: 'Monto Enajenación',
      VEHICLE_KEY: 'Clave Vehicular',
      BRAND: 'Marca',
      TYPE: 'Tipo',
      MODEL: 'Modelo',
      ENGINE_NUMBER: 'Número de Motor',
      SERIAL_NUMBER: 'Numero de serie',
      VALUE: 'Valor',
      CUSTOM_INFORMATION: 'Información aduanera',
      CUSTOM_NUMBER: 'Número',
      CUSTOM_DATE: 'Fecha',
      CUSTOM_TEXT: 'Aduana',
      PERMIT_NUMBER: 'Número de permiso o autorización de construcción',
      STREET: 'Calle',
      EXTERIOR_NUMBER: 'Exterior',
      INTERIOR_NUMBER: 'Interior',
      COLONY: 'Colonia',
      LOCATION: 'Localidad',
      REFERENCE: 'Referencia',
      MUNICIPALITY: 'Municipio',
      POSTAL_CODE: 'Código Postal',
      PROPERTY_TYPE: 'Tipo de inmueble',
      OPERATION_DETAILS: 'Detalles de la operación',
      NOTARY_DATA: 'Datos del Notario',
      DEALER_DATA: 'Datos del Enajenante',
      NOTARY_INSTRUMENT: 'Número de instrumento notarial',
      NOTARY_DATE: 'Fecha de instrumento notarial',
      OPERATION_AMOUNT: 'Monto de operación',
      NOTARY_CURP: 'CURP del notario',
      NOTARY_NUMBER: 'Número de notaría',
      NOTARY_SUBTOTAL: 'Subtotal',
      NOTARY_IVA: 'IVA',
      FEDERAL_ENTITY: 'Entidad Federativa',
      NOMINATION: 'Adscripción',
      IS_PROPERTY: '¿Es copropiedad o sociedad conyugal?',
      DEALER_FIRST_NAME: 'Nombre',
      DEALER_LAST_NAME: 'Apellido paterno',
      DEALER_MOTHER_LAST_NAME: 'Apellido Materno',
      DEALER_RFC: 'RFC',
      DEALER_CURP: 'CURP',
      DEALER_PERCENTAGE: 'Porcentaje',
      IS_RECEPTOR:
        '¿La propiedad será adquirida por copropiedad o sociedad conyugal?',
      RECEPTOR_DATA: 'Datos del Adquiriente',
      REASON: 'Motivo de Traslado',
      OPERATION_TYPE: 'Tipo de operación',
      CLAIM_CODE: 'Clave de pedimento',
      ORIGIN_CERTIFICATE_CODE: 'Certificado Origen',
      CERTIFICATE_NUMBER: 'Número de certificado',
      EXPORT_NUMBER: 'Número de exportador confiable',
      INCOTERM_CODE: 'Clave de INCOTERM',
      SUB_DIVISION: 'Subdivisión',
      OBSERVATION: 'Observaciones',
      USD_EXCHANGE_RATE: 'Tipo de cambio USD',
      TOTAL_USD: 'Total USD',
      TRANSFER_REASON: 'Motivo de traslado',
      ISSUER_CURP: 'CURP del emisor',
      OWNER_REGISTER_TAX_ID: 'Número de Registro ID Tributario del propietario',
      RESIDENCE_TAX: 'Residencia Fiscal',
      RECEIVER_REGISTER_TAX_ID: 'Número de Registro ID Tributario del receptor',
      RECIPIENT_NAME: 'Nombre del Destinatario',
      IDENTIFICATION_NUMBER: 'Número de Identificación',
      TARIFF_FRACTION: 'Fracción Arancelaria',
      GOOD_QUANTITY: 'Cantidad de bienes en la aduana',
      MEASUREMENT_UNIT: 'Unidad de Medida',
      CUSTOMER_UNIT_PRICE: 'Valor unitario en Aduana',
      DOLLAR_VALUE: 'Valor en Dólares',
      SUB_MODEL: 'Sub Modelo',
      OPERATION_INFO: 'Datos de Operación',
      OWNER_INFO: 'Propietario',
      RECEIVER_INFO: 'Datos del Receptor',
      ADDRESS_INFO: 'Destinatario',
      RECIPIENT_ADDRESS_INFO: 'Domicilio del Destinatario',
      COMMODITY_INFO: 'Mercancía',
      SPECIFIC_DESCRIPTION_INFO: 'Descripción Especificas',
      ISSUER_DATA: 'Datos del emisor',
      DESTINATION_INFO: 'Destinatario',
      SPECIFIC_DESCRIPTION: 'Descripciones especificas',
      TEX_REGISTRY_IDENTIFICATION_NUMBER:
        'Número de identificación ó registro Fiscal',
      RECIPIENT_BUSINESS_NAME: 'Nombre o Razón social del destinatario',
      EXPORTED_IDENTIFICATION_NO:
        'Clave de identificación de la mercancía exportada',
      EXPORTED: 'Exportación',
      TRAFFIC_FRACTION: 'Fracción Arancelaria',
      COMMERCIAL_GOOD_QUANTITY: 'Cantidad de bienes en la aduana',
      COMMERCIAL_MEASUREMENT_UNIT: 'Unidad de medida aplicable a la mercancia',
      COMMERCIAL_UNIT_VALUE: 'Valor unitario en la aduana (USD)',
      COMMERCIAL_TOTAL: 'Valor Total (USD)',
      ADD_MERCHANDISE: 'Agregar Mercancía',
      UPDATE_MERCHANDISE_DETAILS: 'Actualizar Mercancía',
      ADD_NOTARY_PROPERTY: 'Agregar registro de immueble',
      ADD_COMMERCIAL_PROPERTY: 'Agregar Propietario',
      ADD_COMMERCIAL_DESTINATION: 'Agregar Destinatario',
      UPDATE_COMMERCIAL_DESTINATION: 'Destinatario de actualización',
      ADD_DESCRIPTION: 'Agregar Descripción',
      MERCHANDISE_MARK: 'Marca de la mercancia',
      MERCHANDISE_MODEL: 'Modelo de la mercancia',
      MERCHANDISE_SUB_MODEL: 'Submodelo de la mercancia',
      MERCHANDISE_SERIAL: 'Número de serie de la mercancia',
      REMARK: 'Motivo de cancelación',
      PAYMENT_DATE: 'Fecha de pago',
      BANK: 'Banco',
      MOVEMENT_NUMBER: 'Número de movimiento',
      PAID: 'Pagada',
      SERVER_RESPONSE: 'Respuesta del servidor',
      CFDI_MESSAGE:
        'Por favor llene y agregue los campos de cfdi relacionado antes de presionar Agregar pago',
      PAYMENT_MESSAGE: 'Por favor presione agregar Pago',
      SELECTED_MORE_CFDI: 'Seleccionar más CFDI relacionados',
      MORE_CFDI: 'Seleccionar más CFDI relacionados',
      SHOW_BUTTON: 'Buscar CFDI',
      ADD_CFDI_BUTTON: 'Complemento de pagos',
      SHOW_HIDE_COLUMN: 'Mostrar / Ocultar columnas',
      CHOSE_ONE: 'Seleccione uno',
      SELECT_RECORD: 'Por favor seleccione registros',
      AUTHORIZATION_NUMBER: 'Número de autorización',
      AUTHORIZATION_DATE: 'Fecha de autorización',
      LEGEND: 'Leyenda',
      SAME_ADDRESS_FOR_RECIPIENT: 'Usar la misma dirección para Destinatario',
      IS_NATIONAL_TERRITORY_LEAVE:
        '¿Las mercancías salen del territorio nacional?',
      IS_GOOD_ENTER_OR_LEAVE_NATIONAL:
        '¿Las mercancías o bienes ingresan o salen de territorio nacional?',
      ENTRY_ROOT: 'Vía de entrada o salida de los bienes o mercancías',
      DISTANCE: 'Total de distancia recorrida en Kilometros',
      IS_LOCATION_ACTIVE: 'Ubicación',
      STATION_ID: 'Tipo de estación durante el traslado de mercancías',
      TRAVELLED_DISTANCE:
        'Distancia recorrida en kilometros del punto origen al destino',
      SOURCE_LABEL: 'Origen',
      ORIGIN_ID: 'Id origen',
      SENDER_RFC: 'RFC del remitente',
      SENDER_NAME: 'Nombre del remitente',
      REGISTERED_TAX_ID: 'Número de registro ID tributario',
      TAX_RESIDENT: 'Residencia Fiscal',
      STATION_NUMBER: 'Nombre de estación',
      STATION_LIST_ID: 'Número de estación',
      PORT_TYPE: 'Tipo de puerto donde se documentan los bienes o mercancías',
      DEPARTURE_DATE_TIME: 'Fecha y hora de salida',
      RECEPTOR_ADDRESS: 'Destinatario',
      RECEPTOR_ID: 'Id destino',
      RECEPTOR_ORIGIN_ID: 'Id origen / destino',
      RECEPTOR_SENDER_NAME: 'Nombre del remitente / destinatario',
      CARRY_RECEPTOR_RFC: 'RFC del destinatario',
      CARRY_RECEPTOR_NAME: 'Nombre del destinatario',
      RECEPTOR_REGISTERED_TAX_ID: 'Número de registro ID tributario',
      RECEPTOR_TAX_RESIDENT: 'Residencia Fiscal',
      RECEPTOR_STATION_NUMBER: 'Número de estación',
      RECEPTOR_STATION_LIST_ID: 'Nombre de estación',
      RECEPTOR_PORT_TYPE:
        'Tipo de puerto donde se documentan los bienes o mercancías',
      ARRIVED_DATE_TIME: 'Fecha y hora de llegada',
      ADDRESS_LABEL: 'Domicilio',
      RECEPTOR_STREET: 'Calle',
      RECEPTOR_OUTDOOR: 'Número exterior',
      RECEPTOR_INTERIOR: 'Número interior',
      RECEPTOR_COLONY: 'Colonia',
      RECEPTOR_LOCATION: 'Localidad',
      RECEPTOR_REFERENCE: 'Referencia',
      RECEPTOR_MUNICIPALITY: 'Municipio',
      RECEPTOR_STATE: 'Nombre del origen / destino',
      RECEPTOR_POSTAL_CODE: 'Codigo postal',
      COUNTRY_ID: 'País',
      ADD_LOCATION: 'Agregar Ubicación',
      UPDATE_LOCATION: 'Actualizar Ubicación',
      IS_GOOD: 'Mercancías',
      GOOD_TOTAL_GROSS_WEIGHT: 'Peso bruto total',
      GOOD_MEASUREMENT_UNIT: 'Unidad de medida de los bienes o mercancías',
      GOOD_TOTAL_NET_WEIGHT: 'Peso neto total',
      GOOD_TOTAL_GOOD_UNIT: 'Número total de mercancías',
      GOOD_APPRAISAL_FEE: 'Cargo por tasación',
      GOOD: 'mercancía',
      GOOD_PRODUCT_KEY:
        'Clave de producto de los bienes o mercancías transportados',
      GOOD_STCC_PRODUCT_KEY: 'Clave de producto STCC',
      GOOD_DESCRIPTION: 'Descripción',
      GOOD_MOVED_QUANTITY: 'Cantidad de bienes o mercancías que se trasladan',
      GOOD_MEASUREMENT_CODE: 'Clave de unidad de medida',
      GOOD_UNIT_OF_MEASUREMENT: 'Unidad de medida de los bienes o mercancías',
      GOOD_PACKAGING: 'Dimensiones del empaque de los bienes o mercancías',
      IS_GOOD_HAZARDOUS_MATERIAL:
        '¿Los bienes o mercancías son considerados material peligroso?',
      GOOD_HAZARDOUS_MATERIAL_TYPE:
        'Clave del tipo de material peligroso que se traslada',
      GOOD_PACKAGING_TYPE:
        'Tipo de embalaje necesario para transportar la mercancía o mercancía.',
      GOOD_PACKAGING_DESCRIPTION:
        'Descripción del embajale para transportar los bienes o mercancías',
      GOOD_PACKAGING_WEIGHT: 'Peso en Kilogramos de los bienes o mercancías',
      GOOD_PACKAGING_VALUE: 'Valor de los bienes o mercancías',
      GOOD_CURRENCY: 'Moneda',
      GOOD_PACKAGING_TRAFFIC: 'Fracción arancelaria de los bienes o mercancías',
      GOOD_PACKAGING_UUID: 'UUID del CFDI complemento comercio exterior',
      ADD_GOOD: 'Agregar Mercancía',
      UPDATE_GOOD: 'Actualizar Mercancía',
      TRANSPORTED_GOOD: 'Agregar Bienes transportados',
      ADD_TRANSPORTED_GOOD: 'Agregar Bienes transportados',
      MOVED_QUANTITY: 'Cantidad de mercancías que se trasladan',
      DESTINATION_ID: 'Id destino',
      TRANSPORT_CODE: 'Clave de transporte',
      MERCHANDISE_DETAILS: 'Detalle de mercancía',
      ADD_MERCHANDISE_DETAILS: 'Agregar Detalle de mercancía',
      PACKAGING_UNIT: 'Clave de unidad de medida',
      GROSS_WEIGHT: 'Peso bruto de las mercancías',
      NET_WEIGHT: 'Peso neto de las mercancías',
      DEFECT_WEIGHT: 'Peso bruto menos el peso neto de las mercancías',
      PIECES: 'Número de piezas de la mercancía',
      AUTO_TRANSPORT: 'Autotransporte Federal',
      PERMISSION_TYPE_ID: 'Tipo de permiso proporcionado por la SCT',
      SCT_PERMIT_NUMBER: 'Número de permiso proporcionado por la SCT',
      INSURER_NAME:
        'Nombre de la aseguradora que cubre los riesgos del autrotransporte',
      INSURANCE_POLICY_NUMBER: 'Número de póliza del seguro',
      VEHICLE_IDENTIFICATION: 'Identificación vehícular',
      FEDERAL_TRUCKING_TYPE_ID: 'Clave de nomenclatura del autotransporte',
      MOTOR_LICENCE_PLAT: 'Placa vehicular del autotransporte',
      MOTOR_YEAR: 'Año o modelo del autotransporte',
      TRAILER: 'Remolque',
      TRANSPORT_TRAILER: 'Remolque CCP',
      TRAILER_TYPE_ID: 'Subtipo de remolque o semirremolque',
      TRAILER_PLATE: 'Placa vehicular del remolque o semirremolque',
      ADD_TRAILER: 'Agregar registro de remolque',
      MARITIME_TRANSPORT: 'Tranporte Marítimo',
      MARITIME_PERMISSION_TYPE_ID: 'Tipo de permiso proporcionado por la SCT',
      MARITIME_PERMIT_NUMBER: 'Número de permiso proporcionado por la SCT',
      MARITIME_INSURER_NAME:
        'Nombre de la aseguradora que cubre el seguro de protección de la embarcación',
      MARITIME_POLICY_NUMBER:
        'Número de póliza del seguro de protección de la embarcación',
      BOAT_TYPE_ID: 'Tipo de embarcación',
      VESSEL_REGISTRATION: 'Matrícula de la embarcación',
      VESSEL_IMO_NUMBER: 'Número OMI de la embarcación',
      BOAT_YEAR: 'Año de la embarcación',
      BOAT_NAME: 'Nombre de la embarcación',
      VESSEL_COUNTRY_ID: 'Nacionalidad de la embarcación',
      GROSS_TONNAGE_UNIT: 'Unidades de arqueo bruto',
      LOAD_TYPE_ID: 'Tipo de carga',
      ITC_CERTIFICATE: 'Número de certificado emitido por la ITC',
      LENGTH_OF_LENGTH: 'Longitud de eslora (definida en pies)',
      TIP_LENGTH: 'Longitud de Puntal (definida en pies)',
      SLEEVE_LENGTH: 'Longitud de manga (definida en pies)',
      DRAFT_LENGTH: 'Longitud de calado (definida en pies)',
      SHIPPING_LINE: 'Nombre de la línea naviera que gestiona el traslado',
      SHIPPING_AGENT: 'Nombre del agente naviero que gestiona el traslado',
      AGENT_NUMBER_TYPE_ID:
        'Catálogo de número autorización agente naviero consignatario',
      TRAVEL_NUMBER: 'Número de viaje con el que se identifica el traslado',
      BILL_LADING_NUMBER: 'Número de conocimiento de embarque',
      TEMPORARY_NAVIGATION_PERMIT: 'Permiso temporal de navegación',
      CONTAINER_HEADING: 'Contenedor',
      CONTAINER_IDENTIFICATION_NUMBER:
        'Matricula o número de identificación del contenedor',
      SEA_CONTAINER_ID: 'Tipo de contenedor marítimo',
      SEAL_NUMBER: 'Número de sello o precinto de los contenedores marítimos',
      IDCCP_LAND_TRANSPORT_DOCUMENT: 'IdCCP del documento de autotransporte terrestre',
      LICENSE_LAND_TRANSPORT_DOCUMENT: 'Placa vehícular del documento de autotransporte terrestre',
      DATE_LAND_TRANSPORT_DOCUMENT: 'Fecha de certificación del documento de autotransporte terrestre',
      ADD_CONTAINER: 'Agregar Contenedor',
      EDIT_CONTAINER: 'Actualizar Contenedor',
      AIR_TRANSPORT: 'Transporte aéreo',
      AIR_PERMISSION_TYPE_ID: 'Tipo de permiso proporcionado por la SCT',
      AIR_PERMISSION_NUMBER: 'Número de permiso proporcionado por la SCT',
      AIRCRAFT_REGISTRATION: 'Matrícula de la aeronave',
      AIR_ISSUER_NAME:
        'Nombre de la aseguradora que cubre los riesgos del medio',
      AIR_INSURANCE_POLICY:
        'Número de póliza del seguro de protección de la aeronave',
      AIR_GOOD_TRANSFER:
        'Guía aérea con los que se trasladan los bienes o mercancias',
      AIR_CONTACT_PLACE: 'Lugar de contrato',
      AIR_CARRIER_RFC: 'RFC del transportista',
      AIR_TYPE_ID: 'Código del transportista',
      CARRIER_TAX_NUMBER: 'Número ID tributario del transportista',
      AIR_COUNTRY_ID: 'Residencia Fiscal del transportista',
      CARRIER_NAME: 'Nombre del transportista',
      SHIPPER_RFC: 'RFC del embarcador',
      SHIPPER_TAX_NUMBER: 'Número ID tributario del embarcador',
      SHIPPER_COUNTRY_ID: 'Residencia Fiscal del embarcador',
      SHIPPER_NAME: 'Nombre del embarcador',
      RAILWAY_TRANSPORT: 'Transporte Ferrovíario',
      SERVICE_TYPE_ID: 'Tipo de servicio',
      RAILWAY_INSURER_NAME:
        'Nombre de la aseguradora que cubre los riesgos vía férrea',
      RAILWAY_POLICY_NUMBER: 'Número de póliza del seguro',
      RAILWAY_TRANSPORT_RFC:
        'RFC del concesionario o asignatario del transporte',
      RIGHT_WAY: 'Derechos de paso',
      WAY_TYPE_ID: 'Clave de derecho de paso pagado por el transportista',
      RAILWAY_PAID_BY: 'Kilometros pagados por el transportista vía férrea',
      ADD_RIGHT: 'Agregar Derechos de paso',
      CAR_HEADING: 'Carro',
      CAR_TYPE_ID:
        'Tipo de carro utilizado en el traslado de los bienes o mercancias',
      REGISTRATION: 'Matrícula del carro',
      GUIDANCE_NUMBER:
        'Número de guía asignado al contenedor o carro de ferrocarril',
      NET_TONE: 'Toneladas netas contenidas en el contenedor',
      ADD_CAR: 'Agregar Carro',
      CONTAINER_TYPE_ID: 'Tipo de contenedor',
      EMPTY_CONTAINER_WEIGHT: 'Peso en kilogramos del contenedor vacio',
      GOOD_NET_WEIGHT: 'Peso neto en kilogramos de los bienes o mercancias',
      TRANSPORT_HEADING: 'Clave de transporte',
      TRANSPORT_ID: 'Clave de transporte',
      OPERATOR_HEADING: 'operadores',
      OPERATOR_RFC: 'RFC del operador',
      OPERATOR_LICENCE: 'Número de licencia',
      OPERATOR_NAME: 'Nombre del operador',
      OPERATOR_TAX_NUMBER: 'Número ID tributario del operador',
      OPERATOR_COUNTRY_ID: 'Residencia Fiscal del operador',
      OPERATOR_ADDRESS_HEADING: 'Domicilio del operador',
      OPERATOR_ADDRESS_STREET: 'Calle',
      OPERATOR_ADDRESS_EXTERIOR_NUMBER: 'NumeroExterior',
      OPERATOR_ADDRESS_INTERIOR_NUMBER: 'NumeroInterior',
      OPERATOR_ADDRESS_COLONY: 'Colonia',
      OPERATOR_ADDRESS_LOCATION: 'Localidad',
      OPERATOR_ADDRESS_REFERENCE: 'Referencia',
      OPERATOR_ADDRESS_MUNICIPALITY: 'Municipio',
      OPERATOR_ADDRESS_STATE: 'Estado',
      OPERATOR_ADDRESS_POSTAL_CODE: 'Codigo Postal',
      OPERATOR_ADDRESS_ADD: 'Agregar Domicilio del operador',
      OWNER_HEADING: 'propietario',
      OWNER_RFC: 'RFC del propietario',
      OWNER_NAME: 'Nombre del propietario',
      OWNER_TAX_NUMBER: 'Número ID tributario del propietario',
      OWNER_COUNTRY_ID: 'Residencia Fiscal del propietario',
      OWNER_ADDRESS_HEADING: 'Domicilio del propietario',
      OWNER_ADDRESS_ADD: 'Agregar Domicilio del propietario',
      TENANT_HEADING: 'Arrendatario',
      TENANT_RFC: 'RFC del Arrendatario',
      TENANT_NAME: 'Nombre del Arrendatario',
      TENANT_TAX_NUMBER: 'Número ID tributario del Arrendatario',
      TENANT_COUNTRY_ID: 'Residencia Fiscal del Arrendatario',
      TENANT_ADDRESS_HEADING: 'Domicilio del Arrendatario',
      TENANT_ADDRESS_ADD: 'Agregar Domicilio del Arrendatario',
      NOTIFY_HEADING: 'Notificado',
      NOTIFY_RFC: 'RFC del notificado',
      NOTIFY_NAME: 'Nombre del notificado',
      NOTIFY_TAX_NUMBER: 'Número ID tributario del notificado',
      NOTIFY_COUNTRY_ID: 'Residencia Fiscal del notificado',
      NOTIFY_ADDRESS_HEADING: 'Domicilio del notificado',
      NOTIFY_ADDRESS_ADD: 'Agregar Domicilio del notificado',
      GOOD_COUNTRY_ID: 'País origen o destino de las mercancías',
      LOCATION_TYPE: 'Tipo de ubicación',
      LOCATION_LABEL_ID: 'Id de ubicación',
      PADIMENT: 'Pedimento',
      PADIMENT_ADD: 'Agregar Pedimento',
      IDENTIFICATION_GUIDE_ADD: 'Agregar Guías de identificación',
      IDENTIFICATION_GUIDE: 'Guías de identificación',
      PACKAGE_GUIDE_NUMBER: 'Numero de guia del paquete',
      PACKAGE_DESCRIPTION: 'Descripción del paquete',
      PACKAGE_WEIGHT: 'Peso del paquete en Kilogramos',
      INSURANCE_LABEL: 'Seguros',
      INSURANCE_RISK_COVER:
        'Aseguradora que cubre los riesgos por responsabilidad civil',
      POLICY_RISK_COVER:
        'Póliza que cubre los riesgos por responsabilidad civil',
      INSURER_COVER_ENVIRONMENT_DAMAGE:
        'Aseguradora que cubre posibles daños al medio ambiente',
      POLICY_COVER_ENVIRONMENT_DAMAGE:
        'Póliza que cubre posibles daños al medio ambiente',
      INSURER_COVER_CARGO_DAMAGE:
        'Aseguradora que cubre los riesgos de la carga',
      POLICY_COVER_CARGO_DAMAGE: 'Póliza que cubre los riesgos de la carga',
      INSURANCE_PREMIUM_VALUE: 'Valor de la prima del seguro contratado',
      TRANSPORT_FIGURE_LABEL: 'Figura transporte',
      TRANSPORT_FIGURE_ADD: 'Agregar Figura de transporte',
      TRANSPORT_FIGURE_EDIT: 'Actualizar Figura de transporte',
      TRANSPORT_FIGURE_CATEGORY_ID: 'Tipo de Figura de transporte',
      TRANSPORT_FIGURE_RFC: 'RFC de Figura de transporte',
      TRANSPORT_FIGURE_LICENSE_NUMBER: 'Número de licencia',
      TRANSPORT_FIGURE_NAME: 'Nombre de Figura transporte',
      TRANSPORT_FIGURE_TAX_ID:
        'Número de id tributario de Figura de transporte',
      TRANSPORT_FIGURE_COUNTRY_ID: 'Residencia Fiscal de Figura de transporte',
      TRANSPORT_PART_ADD: 'Agregar Parte de transporte',
      TRANSPORT_PART_ID: 'Partes de transporte',
      TRANSPORT_FIGURE_ADDRESS_COUNTRY_ID: 'Residencia Fiscal',
      RAIL_TRAFFIC_TYPE_ID: 'Tipo de tráfico',
      UUID_SUBSTITUTE: 'UUID substituto',
      ADD_UUID_SUBSTITUTE: 'Agregar de UUID substituto',
      MESSAGE: {
        ADD_SUCCESS: 'CFDI emitido correctamente',
        EDIT_SUCCESS: 'Informacién modificada correctamente',
        DRAFT_SUCCESS: 'Factura guardada como borrador correctamente.'
      }
    },
    INVOICE_BATCH: {
      SERIE: 'Serie',
      ISSUER_RFC: 'RFC del emisor',
      COMPANY: 'Empresa',
      RECEPTOR_RFC: 'RFC del receptor',
      RECEPTOR_NAME: 'Nombre del receptor',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Descuento',
      TOTAL_TRANSFER: 'Total Impuestos Trasladados',
      TOTAL_RETAINED: 'Total Impuestos Retenidos',
      PAYMENT_DATE: 'Fecha de Pago',
    },
    PAYROLL_PREVIOUS: {
      TITLE: 'Recibos de Nómina 3.3',
      SENDER_INFORMATION: 'Datos del emisor',
      DRAFT_TITLE: 'Borradores nómina 3.3',
      CANCELLED_PAYROLL: 'Nómina Cancelada 3.3',
      FAILED_PAYROLL: 'Intentos Fallidos Nómina 3.3',
      PAYROLL_BATCH: 'Nómina Batch 3.3',
      PAYROLL_BATCH_CANCELLED: 'Cancelaciones Batch 3.3',
      PAYROLL_BATCH_FAILED: 'Intentos Fallidos Batch 3.3',
      NEW_PAYROLL: 'Nuevo Recibo 3.3',
      ADD_TITLE: 'Añadir nómina',
      EDIT_TITLE: 'Editar nómina',
      ADD_TITLE33: 'Añadir nómina 3.3',
      EDIT_TITLE33: 'Editar nómina 3.3',
      MANAGE_TITLE: 'Gestionar',
      DELETE_TITLE: 'Borrar nómina',
      ADD_TITLE_RELATION: 'Añadir Nómina Batch con CFDI relacionados',
    },
    PAYROLL_MULTIPLE: {
      TITLE: 'Gestión nómina 4.0',
      SENDER_INFORMATION: 'Datos del emisor',
      DRAFT_TITLE: 'Borradores nómina 4.0',
      CANCELLED_PAYROLL: 'Nómina cancelada 4.0',
      FAILED_PAYROLL: 'Intentos fallidos nómina 4.0',
      PAYROLL_BATCH: 'Gestión nómina global batch',
      PAYROLL_BATCH_ADD: 'Añadir Nómina Batch 4.0',
      PAYROLL_BATCH_CANCELLED: 'Cancelaciones nómina global batch',
      PAYROLL_BATCH_FAILED: 'Fallidos nómina global batch',
      NEW_PAYROLL: 'Nuevo Recibo 3.3',
      ADD_TITLE: 'Añadir recibo múltiples complementos 4.0',
      EDIT_TITLE: 'Editar recibo múltiples complementos 4.0',
      MANAGE_TITLE: 'Gestionar',
      DELETE_TITLE: 'Borrar nómina',
      ADD_TITLE_RELATION: 'Añadir Nómina Batch con CFDI relacionados',
      MESSAGE: {
        ADD_SUCCESS: 'Nómina creada correctamente',
        EDIT_SUCCESS: 'Nómina creada correctamente',
        DRAFT_SUCCESS: 'Nómina añadido al borrador correctamente.',
      },
    },
    PAYROLL: {
      TITLE: 'Recibos de Nómina 4.0',
      SENDER_INFORMATION: 'Datos del emisor',
      DRAFT_TITLE: 'Borradores nómina 4.0',
      CANCELLED_PAYROLL: 'Nómina Cancelada 4.0',
      FAILED_PAYROLL: 'Intentos Fallidos Nómina 4.0',
      PAYROLL_BATCH: 'Nómina Batch 4.0',
      PAYROLL_BATCH_ADD: 'Añadir Nómina Batch 4.0',
      PAYROLL_BATCH_CANCELLED: 'Cancelaciones Batch 4.0',
      PAYROLL_BATCH_FAILED: 'Intentos Fallidos Batch 4.0',
      ADD_TITLE: 'Añadir nómina 4.0',
      EDIT_TITLE: 'Editar nómina 4.0',
      MANAGE_TITLE: 'Gestionar 4.0',
      DELETE_TITLE: 'Borrar nómina',
      ADD_TITLE_RELATION: 'Añadir Nómina Batch con CFDI relacionados',
      PAYROLL_TYPE: 'Tipo de nómina',
      FOLLIO_FISCAL: 'Folio fiscal',
      INTERNAL_FOLIO: 'Folio interno',
      ISSUE_DATE: 'Fecha de emisión',
      EMPLOYEE_RFC: 'RFC del empleado',
      EMPLOYEE_CURP: 'CURP del empleado',
      EMPLOYEE_NAME: 'Nombre del empleado',
      INITIAL_PAYMENT_DATE: 'Fecha inicial de pago',
      FINAL_PAYMENT_DATE: 'Fecha final de pago',
      WORKED_DAYS: 'Dias trabajados',
      TOTAL_DISABILITY: 'Total incapacidades',
      TOTAL_TO_PAY: 'Total a pagar',
      CREATION_DATE: 'Fecha de Creación',
      COMPANY_EMPLOYEE: 'Empresa/Empleado',
      CFD_DATA: 'Datos CFDI',
      PAYMENT_SENDER_INFORMATION: 'Datos de la razón social o sucursal',
      PERCEPTION: 'Percepciones',
      RECEIVER_DATA: 'Datos De Empleado',
      ADD_PERCEPTION: 'Agregar Percepción',
      DEDUCTION: 'Deducciones',
      DISABILITY: 'Incapacidades',
      OTHER_PAYMENT: 'Otros pagos',
      TOTAL: 'Totales',
      ADDITIONAL_INFORMATION: 'Información adicional',
      OBSERVATIONS: 'Observaciones',
      DELETE_CONFIRM_MESSAGE: '¿Seguro que quieres eliminar este registro?',
      ADD_EMPLOYEE: 'Añadir empleado',
      COMPANY_TITLE: 'Añadir empresa',
      DOCUMENT_TYPE: 'Tipo de Documento',
      TYPE_OF_CURRENCY: 'Tipo de Moneda',
      EXCHANGE_RATE: 'Tipo de Cambio',
      BUSINESS: 'Empresa',
      PAYMENT_BUSINESS: 'Razón social o sucursal',
      EMPLOYEE: 'Nombre del Empleado',
      RFC: 'RFC',
      DOCUMENT_TYPE_2: 'Tipo de comprobante',
      PAYMENT_DATE: 'Fecha de Pago',
      PAYMENT_START_DATE: 'Fecha de inicio del Pago',
      PAYMENT_END_DATE: 'Fecha de finalización del Pago',
      DAYS_PAID: 'Días pagados',
      PERCEPTION_NAME: 'Nombre de la Percepción',
      CLUSTERING_KEY: 'Clave Agrupadora DIMM',
      PERCEPTION_KEY: 'Clave de Percepción',
      DESCRIPTION: 'Descripcion',
      SAVED_AMOUNT: 'Importe Gravado',
      EXEMPT_AMOUNT: 'Importe Exento',
      EXTRA_HOUR: 'Horas Extra',
      COMPENSATION_YEAR_OF_SERVICE: 'Años de Servicio',
      DAYS_WORK_PERFORM: 'Dias en que el trabajador realizo horas extra',
      DAYS_PAYMENT_TYPE: 'Tipo de Pago',
      NUMBER_OF_OVERTIME: 'Numero de Horas extra',
      AMOUNT_PAID: 'Importe Pagado',
      ADD_EXTRA_HOUR: 'Agregar Horas Extra',
      DEDUCTION_NAME: 'Nombre de la Deducción',
      PEYROLL_DEDUCTION_KEY: 'Clave de deducción',
      AMOUNT: 'Importe',
      ADD_DEDUCTION: 'Agregar Deducción',
      DISABILITY_DAYS: 'Dias Incapacidad',
      TYPE_OF_DISABILITY: 'Tipo de Incapacidad',
      DISABILITY_AMOUNT: 'Importe Incapacidad',
      WAY_TO_PAY: 'Formas de Pago',
      PAYMENT_METHOD: 'Método de Pago',
      EXPEDITION_PLACE: 'Lugar de Expedición',
      NAME_OF_OTHER_PAYMENT: 'Nombre de otro Pago',
      GROUPING_KEY: 'Clave Agrupadora',
      DEDUCTION_CODE: 'Clave Deduccion',
      CHANGE_VALUE: 'Cambiar Valores',
      ADD_ANOTHER_PAYMENT: 'Agregar otro Pago',
      EMPLOYMENT_SUBSIDY: 'Subsidio al Empleo',
      SUBSIDY_CAUSE: 'Subsidio Causado',
      POSITIVE_BALANCE: 'Saldo a Favor',
      YEAR: 'Año',
      REMAINDER: 'Remanente',
      QUANTITY: 'Cantidad',
      UNIT_OF_MEASUREMENT: 'Unidad de Medida',
      UNIT_VALUE: 'Valor Unitario',
      SUB_TOTAL: 'Subtotal',
      TOTAL_PERCEPTION: 'Total percepciones',
      TOTAL_OTHER_PAYMENT: 'Total Otros Pagos',
      TOAL_ISR: 'Total ISR',
      RECEIPT_TYPE: 'Tipo de comprobante',
      PAYMENT_FOR: 'Pagos por ',
      IF_HEAD: 'Separación e ',
      EXTRA_HOUR_TYPE: 'Tipo de hora extra',
      ADD_DISABILITY: 'Añadir incapacidad',
      TOTAL_DISCOUNT: 'Total de descuentos',
      TOTAL_DEDUCTION: 'Total Deducciones',
      MODE: 'Seleccione Uno',
      TOTAL_EXTRA: 'Total Horas Extra',
      OPERATION: 'Operación',
      PAYMENT_FORM: 'Forma de pago',
      TYPE: 'Tipo de nomina',
      PASSWORD: 'Contraseña de facturación',
      PASSWORD_ERROR: 'Contraseña es requerida',
      OTHER_YEAR: 'Año',
      OTHER_REMAINDER: 'Remanente saldo a favor',
      TOTAL_PAID: 'Total Pagado',
      TOTAL_UNA_EXHIBITION: 'Total una Exhibición',
      TOTAL_PARTIALITY: 'Total Parcialidad',
      YEARS_OF_SERVICE: 'Años de servicio',
      DAILY_AMOUNT: 'Monto Diario',
      ULTIMA: 'Ultimo Sueldo Mensual',
      COMMUTATIVE_INCOME: 'Ingreso Acumulable',
      NON_COMMUTATIVE_INCOME: 'Ingreso no acumulable',
      CFDI_USE_ID: 'Uso del CFDI',
      PRODUCT_CATALOG_ID: 'Clave de producto o servicio',
      ADDENDA: 'Addenda',
      OTHER_CODE: 'Clave otros pagos',
      MORE_CFDI: 'Seleccionar CFDI relacionados',
      PAYRLL_COMPLEMENT: 'Complemento de nómina',
      ADD_PAYRLL_COMPLEMENT: 'Agregar complemento de nómina',
      UPDATE_PAYRLL_COMPLEMENT: 'Actualizar complemento de nómina',
      MESSAGE: {
        ADD_SUCCESS: 'Nómina creada correctamente',
        EDIT_SUCCESS: 'Nómina creada correctamente',
        DRAFT_SUCCESS: 'Nómina añadido al borrador correctamente.',
      },
    },
    RETENTION_PREVIOUS: {
      RETENTION_MODULE: 'Administración Retención 1.0',
      RETENTION: 'Gestion de retenciones 1.0',
      RETENTION_DRAFT: 'Borradores Retenciones 1.0',
      CANCELLED_RETENTION: 'Retenciones Canceladas 1.0',
      FAILED_RETENTION: 'Intentos Fallidos Retenciones 1.0',
      RETENTION_BATCH_MODULE: 'Retencion Batch 1.0',
      RETENTION_BATCH: 'Retencion Batch 1.0',
      CANCELLED_RETENTION_BATCH: 'Cancelaciones Batch 1.0',
      FAILED_RETENTION_BATCH: 'Intentos Fallidos Batch 1.0',
    },
    RETENTION: {
      ADD_TITLE: 'Añadir Retencion 2.0',
      EDIT_TITLE: 'Editar Retencion 2.0',
      RETENTION_MODULE: 'Administración Retención 2.0',
      RETENTION: 'Gestion de retenciones 2.0',
      RETENTION_DRAFT: 'Borradores Retenciones 2.0',
      CANCELLED_RETENTION: 'Retenciones Canceladas 2.0',
      FAILED_RETENTION: 'Intentos Fallidos Retenciones 2.0',
      RETENTION_BATCH_MODULE: 'Retencion Batch 2.0',
      RETENTION_BATCH: 'Retencion Batch 2.0',
      RETENTION_BATCH_ADD: 'Añadir Retencion Batch 2.0',
      CANCELLED_RETENTION_BATCH: 'Cancelaciones Batch 2.0',
      FAILED_RETENTION_BATCH: 'Intentos Fallidos Batch 2.0',
      RECEPTOR_RFC: 'RFC Receptor',
      RECEIVER_NAME: 'Nombre receptor',
      TOTAL_RETENTION: 'Total Retencion',
      RETENTION_KEY: 'Clave Retencion',
      DESCRIPTION: 'Descripción',
      COMPANY_NAME: 'Nombre emisor',
      SOCIAL_NAME: 'Nombre De Nominacion O Razon Social',
      RECEPTOR_CURP: 'CURP del receptor',
      NATIONALITY: 'Nacionalidad',
      RECEPTOR: 'Receptor',
      COMPANY: 'Datos del emisor',
      PERIOD: 'Periodo de retenciones',
      TOTAL_WITHHOLDING: 'Total de retención',
      TAX_WITHHELD: 'Impuestos retenidos',
      ADD_WITHHOLDING: 'Agregar impuesto retenido',
      COMPLEMENTO: 'Complementos',
      INTERESES: 'Intereses',
      DIVIDENDOS: 'Dividendos',
      PREMIOS: 'Premios',
      PAGOS_A_EXTRANJEROS: 'Pagos a extranjeros',
      ADDITIONAL_INFORMATION: 'Información adicional',
      REMANTS: 'Remanentes',
      INVESTMENT: 'Enajenación de acciones',
      MORTGAGE_INTEREST: 'Intereses hipotecarios',
      YES: 'SÍ',
      NATIONAL: 'Nacional',
      FOREIGN: 'Extranjero',
      NATIONAL_SOCIETY: 'Sociedad Nacional',
      FOREIGN_SOCIETY: 'Sociedad Extranjera',
      CURP: 'Curp',
      RAZOR_SOCIAL: 'Razon Social',
      IDENTIFICATION_NUMBER: 'Número de identificación fiscal',
      TYPE: 'Tipo',
      ACTIVE: 'Activo',
      RETENTION_KEY_ID: 'Clave de Retención',
      COMPANY_ID: 'Emisor',
      PASSWORD: 'Contraseña de creación de facturas',
      INITIAL_MONTH: 'Mes inicial del período de retención',
      FINAL_MONTH: 'Mes Final del periodo de retención',
      FISCAL_EXERCISE: 'Ejercicio Fiscal',
      OPERATION_AMOUNT: 'Monto Total de Operación',
      RECORDED_AMOUNT: 'Monto de Total Gravado',
      EXEMPT_AMOUNT: 'Monto de Total Exento',
      WITHHOLDING_AMOUNT: 'Monto de Total Retenido',
      MONTHLY_UTILITY: 'Utilidad Bimestral',
      CORRESPONDING_ISR: 'ISR Correspondiente',
      WITHHOLDING_BASE_AMOUNT: 'Importe base de retención',
      WITHHOLDING_TAX_AMOUNT: 'Importe del impuesto retenido',
      RETENTION_TAX_TYPE_ID: 'Tipo de impuesto retenido',
      RETENTION_DISTRIBUTED_INCOME_TYPE_ID: 'Tipo de pago',
      IS_FINANCIAL_YEAR_WITH_SYSTEM:
        'Los intereses del periodo fueron obtenidos en el ejercicio provienen del sistema financiero?',
      IS_INTEREST_EARNED_IN_PERIOD:
        'Los intereses obtenidos fueron retirados en el periodo o ejercicio?',
      IS_INTEREST_EARNED_TO_DERIVATIVE:
        'Los intereses obtenidos corresponden a operaciones financieras derivadas?',
      NORMAL_INTEREST_AMOUNT: 'Importe de interés nóminal',
      REAL_INTEREST_AMOUNT: 'Monto de intereses reales',
      LOSS_INTEREST_AMOUNT: 'Monto de la pérdida por intereses obtenidos',
      WITHHOLDING_DIVIDED_NATIONAL_AMOUNT:
        'Importe o retención del dividendo o utilidad en territorio nacional',
      WITHHOLDING_DIVIDED_FOREIGN_AMOUNT:
        'Importe o retención del dividendo o utilidad en territorio extranjero',
      FOREIGN_HOLDING_AMOUNT:
        'Monto de retención en el extranjero sobre dividendos ddel extranjero',
      NATIONAL_CREDITABLE_INCOME_AMOUNT: 'Monto del ISR acreditable nacional',
      DISTRIBUTED_BY_COMPANY:
        'Dividendo distribuido por sociedades nacionales o extranjeras',
      NATIONAL_CUMULATIVE_DIVIDEND_AMOUNT:
        'Monto del dividendo acumulable nacional',
      FOREIGN_CUMULATIVE_DIVIDEND_AMOUNT:
        'Monto del dividendo acumulable extranjero',
      FEDERAL_ENTITY: 'Entidad Federativa',
      PRIZE_AMOUNT: 'Monto de pago por premio',
      CHARGED_PRIZE_AMOUNT: 'Importe gravado por pago de premio',
      EXEMPT_PRIZE_AMOUNT: 'Importe exento por pago de premio',
      IS_PAYEE_RETAIN:
        '¿El beneficiario del pago es la misma persona que retiene?',
      COUNTRY_ID: 'Clave del país de residencia',
      PAYMENT_METHOD_ID: 'Concepto de pago',
      RFC: 'Rfc',
      CURP_LEGAL: 'CURP del representante legal',
      TAX_PAYEE_NAME: 'Razón social del contribuyente en México',
      RETENTION_FEDERAL_ENTITY_ID:
        '¿el beneficiario del pago es la misma persona que retiene?',
      PARTICIPANTS: 'Proporción de remanentes',
      INTERMEDIATION_CONTRACT: 'contrato de internediación',
      GAIN: 'Ganacia',
      LOST: 'Pérdida',
      BANKING_INSTITUTE: 'El crédito fue otorgado por una institución bancaria',
      OUTSTANDING_BALANCE: 'Saldo Insoluto',
      DEDUCTIBLE_PROPORTION_OF_CREDIT:
        'Proporción deducible del crédito aplicable sobre los intereses reales devengados y pagados',
      TOTAL_INTEREST: 'Monto total de intereses nominales devengados',
      INTEREST_PAID: 'Monto total de intereses nominales devengados y pagados',
      DEDUCTIBLE_REAL_INTEREST:
        'Monto total de intereses reales pagados deducibles',
      CONTRACT_NUMBER: 'Número de contrato del crédito hipotecario',
      NEW_WIDTHHOLDING_CERTIFICATE: 'Nueva constancia de retencion',
      DOCUMENT_DATE: 'Fecha del documento',
      ADD_RELATIONSHIP_CFDI: 'Agregar CFDI',
      ISSUE_CFDI: 'Emitir CFDI',
      DRAFT: 'Borrador',
      MESSAGE: {
        ADD_SUCCESS: 'Retencion creada correctamente',
        EDIT_SUCCESS: 'Retencion creada correctamente',
        CLONE_SUCCESS: 'Clonar creado correctamente',
        DRAFT_SUCCESS: 'Borrador creado correctamente',
      },
    },
    PAYROLL_BATCH_REPORT: {
      TITLE: 'Reporte batch',
      PAYROLL_TYPE: 'Tipo de nómina',
      ISSUER_RFC: 'RFC del empleado',
      UUID: 'uuid',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      SUB_TOTAL: 'Subtotal',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'CURP del empleado',
      EMPLOYEE_NUMBER: 'Número de Empleado',
      EMPLOYEE_NAME: 'Nombre del empleado',
      PAYMENT_METHOD: 'Método de Pago',
      TAX_TYPE_TITLE: 'Régimen Fiscal',
      POSTAL_CODE: 'Código postal',
      TAX_OBJECT: 'Objeto de impuesto',
      PAYMENT_START_DATE: 'Fecha inicial de pago',
      PAYMENT_END_DATE: 'Fecha final de pago',
      PAYMENT: 'Fecha de Pago',
      DAYS_PAID: 'Dias trabajados',
      TOTAL_PERCEPTION: 'Total percepciones',
      DISCOUNT: 'Total Deducciones',
      OTHER: 'Total Otros Pagos',
      TOTAL_ISR: 'Total ISR',
      TOTAL_EXTRAS: 'Total Horas Extra',
      TOTAL_DISABILITY: 'Total incapacidades',
      TOTAL: 'Total a pagar',
      PLACE_OF_EXPEDITION: 'Lugar de Expedición',
      VERSION: 'Version',
      STATUS: 'Estatus del CFDI',
      AMOUNT_TAXED: 'Importe Gravado',
      EXEMPT_AMOUNT: 'Importe Exento',
    },
    PAYROLL_REPORT: {
      TITLE: 'Reporte nómina',
      PAYROLL_TYPE: 'Tipo de nómina',
      ISSUER_RFC: 'RFC del empleado',
      UUID: 'uuid',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      SUB_TOTAL: 'Subtotal',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'CURP del empleado',
      EMPLOYEE_NUMBER: 'Número de Empleado',
      EMPLOYEE_NAME: 'Nombre del empleado',
      PAYMENT_METHOD: 'Método de Pago',
      TAX_TYPE_TITLE: 'Régimen Fiscal',
      POSTAL_CODE: 'Código postal',
      TAX_OBJECT: 'Objeto de impuesto',
      PAYMENT_START_DATE: 'Fecha inicial de pago',
      PAYMENT_END_DATE: 'Fecha final de pago',
      CANCELLED_DATE: 'Fecha de cancelacion',
      PAYMENT: 'Fecha de Pago',
      DAYS_PAID: 'Dias trabajados',
      TOTAL_PERCEPTION: 'Total percepciones',
      DISCOUNT: 'Total Deducciones',
      OTHER: 'Total Otros Pagos',
      TOTAL_ISR: 'Total ISR',
      TOTAL_EXTRAS: 'Total Horas Extra',
      TOTAL_DISABILITY: 'Total incapacidades',
      TOTAL: 'Total a pagar',
      PLACE_OF_EXPEDITION: 'Lugar de Expedición',
      VERSION: 'Version',
      STATUS: 'Estatus del CFDI',
    },
    MULTIPLE_PAYROLL_BATCH_REPORT: {
      TITLE: 'Reporte nómina global batch',
      PAYROLL_TYPE: 'Tipo de nómina',
      UUID: 'uuid',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'CURP del empleado',
      EMPLOYEE_NUMBER: 'Número de Empleado',
      EMPLOYEE_NAME: 'Nombre del empleado',
      PAYMENT_METHOD: 'Método de Pago',
      TAX_TYPE_TITLE: 'Régimen Fiscal',
      POSTAL_CODE: 'Código postal',
      TAX_OBJECT: 'Objeto de impuesto',
      TOTAL_PERCEPTION: 'Total percepciones',
      DISCOUNT: 'Total Deducciones',
      OTHER: 'Total Otros Pagos',
      TOTAL_ISR: 'Total ISR',
      TOTAL_EXTRAS: 'Total Horas Extra',
      TOTAL_DISABILITY: 'Total incapacidades',
      TOTAL: 'Total a pagar',
      PLACE_OF_EXPEDITION: 'Lugar de Expedición',
      VERSION: 'Version',
      STATUS: 'Estatus del CFDI',
      SUB_TOTAL: 'Subtotal',
      AMOUNT_TAXED: 'Importe Gravado',
      EXEMPT_AMOUNT: 'Importe Exento',
    },
    MULTIPLE_PAYROLL_REPORT: {
      TITLE: 'Reporte nómina global web',
      PAYROLL_TYPE: 'Tipo de nómina',
      UUID: 'uuid',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'CURP del empleado',
      EMPLOYEE_NUMBER: 'Número de Empleado',
      EMPLOYEE_NAME: 'Nombre del empleado',
      PAYMENT_METHOD: 'Método de Pago',
      TAX_TYPE_TITLE: 'Régimen Fiscal',
      POSTAL_CODE: 'Código postal',
      TAX_OBJECT: 'Objeto de impuesto',
      TOTAL_PERCEPTION: 'Total percepciones',
      DISCOUNT: 'Total Deducciones',
      OTHER: 'Total Otros Pagos',
      TOTAL_ISR: 'Total ISR',
      TOTAL_EXTRAS: 'Total Horas Extra',
      TOTAL_DISABILITY: 'Total incapacidades',
      TOTAL: 'Total a pagar',
      PLACE_OF_EXPEDITION: 'Lugar de Expedición',
      VERSION: 'Version',
      STATUS: 'Estatus del CFDI',
      SUB_TOTAL: 'Subtotal',
      AMOUNT_TAXED: 'Importe Gravado',
      EXEMPT_AMOUNT: 'Importe Exento',
    },
    RETENTION_BATCH_REPORT: {
      TITLE: 'Informe de retención por lotes',
      CODE: 'Clave Retencion',
      UUID: 'uuid',
      RAZOR_SOCIAL: 'Nombre De Nominacion O Razon Social',
      CURP: 'CURP del receptor',
      IDENTIFICATION_NUMBER: 'Número de identificación',
      RECEPTOR_TYPE: 'Nacionalidad',
      OPERATION_AMOUNT: 'Monto de Total Gravado',
      RECORDED_AMOUNT: 'Monto de Total Exento',
      EXEMPT_AMOUNT: 'Monto de Total Retenido',
      WITHOLDING_AMOUNT: 'Utilidad Bimestral',
      TOTAL: 'Total Retencion',
      CDFI: 'Respuesta de estado de CFDI',
      POSTAL_CODE: 'Código postal del receptor',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      RFC: 'RFC emisor',
      STATUS: 'Estatus del CFDI',
      DESCRIPTION: 'Descripción',
      BUSINESS_NAME: 'Empresa ',
      OBSERVATION: 'Observaciones',
      VERSION: 'version',
    },
    RETENTION_REPORT: {
      TITLE: 'Informe de retención',
      CODE: 'Clave Retencion',
      UUID: 'uuid',
      RAZOR_SOCIAL: 'Nombre De Nominacion O Razon Social',
      CURP: 'CURP del receptor',
      IDENTIFICATION_NUMBER: 'Número de identificación',
      RECEPTOR_TYPE: 'Nacionalidad',
      OPERATION_AMOUNT: 'Monto de Total Gravado',
      RECORDED_AMOUNT: 'Monto de Total Exento',
      EXEMPT_AMOUNT: 'Monto de Total Retenido',
      WITHOLDING_AMOUNT: 'Utilidad Bimestral',
      TOTAL: 'Total Retencion',
      CDFI: 'Respuesta de estado de CFDI',
      POSTAL_CODE: 'Código postal del receptor',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      RFC: 'RFC emisor',
      STATUS: 'Estatus del CFDI',
      DESCRIPTION: 'Descripción',
      BUSINESS_NAME: 'Company ',
      OBSERVATION: 'Observaciones',
      VERSION: 'version',
      NAME: 'Nombre',
    },
    INVOICE_BATCH_REPORT: {
      TITLE: 'Reporte facturas batch',
      VERSION: 'version',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'fecha',
      CODE: 'Clave Retencion',
      RFC: 'RFC',
      STATUS: 'Estatus del CFDI',
      UUID: 'uuid',
      POSTAL_CODE: 'Código postal del receptor',
      TOTAL: 'Total Retencion',
      BUSINESS_NAME: 'Nombre de Emisor',
      OBSERVATION: 'Observaciones',
      EXPORT: 'Exportar',
      PAID: 'Pagada',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Total Deducciones',
      NAME: 'nombre de receptor',
      YEAR: 'Año',
      CONFIRMATION_CODE: 'Clave de confirmacion',
      SERIE: 'Serie del CFDI origen',
      BOARD_TYPE: 'Tipo de actividad fiscal',
      CUSTOMER_RFC: 'Nombre Del Cliente RFC',
      RELATED_UUID: 'UUID relacionado',
      PAYMENT_METHOD: 'M&eacute;todo de Pago',
      PAYMENT_FORM: 'Forma de pago',
      PAYMENT_CONDITION: 'Condiciones de pago',
      TOTAL_TAX_MOVE: 'Total Impuestos Trasladados',
      TOTAL_TAX_WITHHOLD: 'Total Impuestos Retenidos',
      PAYMENT_DATE: 'Fecha de pago',
      BANK: 'Banco',
      MOVEMENT_NUMBER: 'Número de movimiento',
      INVOICE_STATUS: 'Estatus del CFDI',
      USO_CFDI: 'uso cfdi',
      TAX_REGIME: 'RÉGIMEN TRIBUTARIO',
      PERIODICITY: 'PERIODICIDAD',
      MONTH: 'MES',
      TYPE_OF_RELATION: 'Tipo de relación',
      UUID_RELATED: 'uuid related',
      EXCHANGE_RATE: 'Tipo de Cambio',
      CANCELLATION_DATE: 'FECHA DE CANCELACIÓN',
      ADDENDA: 'Addenda',
      MASTER: 'Creado por el usuario',
      CONCEPT: 'Concepto',
    },
    INVOICE_REPORT: {
      TITLE: 'Reporte facturas web',
      RFC: 'RFC emisor',
      STATUS: 'Estatus del CFDI',
      UUID: 'uuid',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'Fecha',
      CODE: 'Clave Retencion',
      POSTAL_CODE: 'Código postal del receptor',
      TOTAL: 'Total Retencion',
      BUSINESS_NAME: 'Nombre de Emisor',
      OBSERVATION: 'Observaciones',
      EXPORT: 'Exportar',
      PAID: 'Pagada',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Total Deducciones',
      YEAR: 'Año',
      CONFIRMATION_CODE: 'Clave de confirmacion',
      SERIE: 'Serie del CFDI origen',
      BOARD_TYPE: 'Tipo de actividad fiscal',
      CUSTOMER_RFC: 'Nombre Del Cliente RFC',
      RELATED_UUID: 'UUID relacionado',
      PAYMENT_METHOD: 'M&eacute;todo de Pago',
      PAYMENT_FORM: 'Forma de pago',
      PAYMENT_CONDITION: 'Condiciones de pago',
      TOTAL_TAX_MOVE: 'Total Impuestos Trasladados',
      TOTAL_TAX_WITHHOLD: 'Total Impuestos Retenidos',
      PAYMENT_DATE: 'Fecha de pago',
      BANK: 'Banco',
      MOVEMENT_NUMBER: 'Número de movimiento',
      INVOICE_STATUS: 'Estatus del CFDI',
      RECEIPT_TYPE: 'tipo de comprobante',
      USO_CFDI: 'uso cfdi',
      TAX_REGIME: 'RÉGIMEN TRIBUTARIO',
      PERIODICITY: 'PERIODICIDAD',
      MONTH: 'MES',
      TYPE_OF_RELATION: 'Tipo de relación',
      UUID_RELATED: 'uuid related',
      EXCHANGE_RATE: 'Tipo de Cambio',
      CANCELLATION_DATE: 'FECHA DE CANCELACIÓN',
      ADDENDA: 'Addenda',
      MASTER: 'Creado por el usuario',
      TAX_OBJECT_CATEGORY_ID: 'Objeto de impuesto',
    },
    INVOICE_PAYMENT_BATCH_REPORT: {
      TITLE: 'Reporte Factura pagos batch',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'fecha',
      CODE: 'Clave Retencion',
      RFC: 'RFC',
      STATUS: 'Estatus del CFDI',
      UUID: 'uuid',
      RECEIPT_TYPE: 'tipo de comprobante',
      VERSION: 'version',
      BUSINESS_NAME: 'Nombre de Emisor',
      BOARD_TYPE: 'Tipo de actividad fiscal',
      CUSTOMER_RFC: 'Nombre Del Cliente RFC',
      NAME: 'nombre de receptor',
      USE_CDFI: 'uso cdfi',
      CANCELLATION_DATE: 'FECHA DE CANCELACIÓN',
      OBSERVATION: 'Observaciones',
      CONFIRMATION_CODE: 'Clave de confirmacion',
      TYPE_OF_RELATION: 'type of relation',
      RELATED_UUID: 'UUID relacionado',
      PAYMENT_METHOD: 'M&eacute;todo de Pago',
      PAYMENT_FORM: 'Forma de pago',
      PAYMENT_CONDITION: 'Condiciones de pago',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Total Deducciones',
      TOTAL: 'Total',
      BY_USER: 'Creado por usuario',
      PAID: 'Pagada',
      PAYMENT_DATE: 'Fecha de pago',
      BANK: 'Banco',
      MOVEMENT_NUMBER: 'Número de movimiento',
      INVOICE_PAYMENT_DATE: 'Fecha de Pago',
      PAYMENTFORM: 'FORMULARIO DE PAGO',
      PAYMENT_EXCHANGE_CURRENCY: 'Moneda de cambio de pago',
      EXCHANGE_RATE: 'Tipo de Cambio',
      PAYMENT_AMOUNT: 'Importe del Pago',
      PAYMENT_RFC: 'RFC DE PAGO',
      BANKNAME: 'nombre de banco',
      RFC_DESTINATION_ACCOUNT_NAME: 'NOMBRE DE CUENTA DE DESTINO RFC',
      PAYMENT_UUID: 'CFDI IMPUESTO',
      PAYMENT_SERIES: 'CDFI SERIE',
      PAYMENT_FOLIO: 'FOLIO DE PAGO',
      PAYMENT_PARTIAL_NUMBER: 'NÚMERO PARCIAL DE PAGO',
      PreviousAmount: 'Monto anterior',
      PaidAmount: 'Monto de pago',
      UnpaidAmount: 'Monto impago',
      PREVIOUS_AMOUNT: 'Importe saldo anterior',
      PAID_AMOUNT: 'Importe pagado',
      UNPAID_AMOUNT: 'Importe insoluto',
      CDFI_STATUS: 'CDFI ESTADO',
    },
    INVOICE_PAYMENT_REPORT: {
      TITLE: 'Reporte Factura pagos web',
      WEB_SERVICE: 'Certificador',
      FOLIO: 'Folio interno',
      DATE: 'fecha',
      CODE: 'Clave Retencion',
      RFC: 'RFC',
      STATUS: 'Estatus del CFDI',
      UUID: 'uuid',
      RECEIPT_TYPE: 'tipo de comprobante',
      VERSION: 'version',
      BUSINESS_NAME: 'Nombre de Emisor',
      BOARD_TYPE: 'Tipo de actividad fiscal',
      CUSTOMER_RFC: 'Nombre Del Cliente RFC',
      NAME: 'nombre de receptor',
      USE_CDFI: 'uso cdfi',
      CANCELLATION_DATE: 'FECHA DE CANCELACIÓN',
      OBSERVATION: 'Observaciones',
      CONFIRMATION_CODE: 'Clave de confirmacion',
      TYPE_OF_RELATION: 'type of relation',
      RELATED_UUID: 'UUID relacionado',
      PAYMENT_METHOD: 'M&eacute;todo de Pago',
      PAYMENT_FORM: 'Forma de pago',
      PAYMENT_CONDITION: 'Condiciones de pago',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Total Deducciones',
      TOTAL: 'Total',
      BY_USER: 'Creado por usuario',
      PAID: 'Pagada',
      PAYMENT_DATE: 'Fecha de pago',
      BANK: 'Banco',
      MOVEMENT_NUMBER: 'Número de movimiento',
      INVOICE_PAYMENT_DATE: 'Fecha de Pago',
      PAYMENTFORM: 'FORMULARIO DE PAGO',
      PAYMENT_EXCHANGE_CURRENCY: 'Moneda de cambio de pago',
      EXCHANGE_RATE: 'Tipo de Cambio',
      PAYMENT_AMOUNT: 'Importe del Pago',
      PAYMENT_RFC: 'RFC DE PAGO',
      BANKNAME: 'nombre de banco',
      RFC_DESTINATION_ACCOUNT_NAME: 'NOMBRE DE CUENTA DE DESTINO RFC',
      PAYMENT_UUID: 'CFDI IMPUESTO',
      PAYMENT_SERIES: 'CDFI SERIE',
      PAYMENT_FOLIO: 'FOLIO DE PAGO',
      PAYMENT_PARTIAL_NUMBER: 'NÚMERO PARCIAL DE PAGO',
      PreviousAmount: 'Monto anterior',
      PaidAmount: 'Monto de pago',
      UnpaidAmount: 'Monto impago',
      CDFI_STATUS: 'CDFI ESTADO',
    },
    INVOICE_PREMIUM_BATCH_REPORT: {
      TITLE: 'Reporte plus pagos batch',
      statusColor: 'Estatus de Pago',
      FOLIO: 'serie',
      DATE: 'Fecha',
      UUID: 'uuid',
      TAX_REGIME: 'Régimen Fiscal',
      CUSTOMER_RFC: 'rfc receptor',
      NAME: 'nombre de receptor',
      USE_CDFI: 'uso cdfi',
      POSTALCODE: 'codigo Postal',
      STATUS: 'Estatus del CFDI',
    },
    INVOICE_PREMIUM_REPORT: {
      TITLE: 'Reporte plus pagos web',
      statusColor: 'Estatus de Pago',
      FOLIO: 'serie',
      DATE: 'Fecha de emisión',
      UUID: 'UUID del CFDI de pago',
      TAX_REGIME: 'Régimen Fiscal',
      CUSTOMER_RFC: 'rfc receptor',
      NAME: 'nombre de receptor',
      USE_CDFI: 'uso cdfi',
      POSTALCODE: 'Código Postal / Domicilio fiscal',
      STATUS: 'Estatus del CFDI',
      SEE_DETAILS: 'VER Detalle de Complemento',
      SEE_INVOICE_DETAILS: 'VER Detalle por Factura',
    },
    REPORT: {
      DOWNLOAD_INVOICE_TITLE: 'Descarga facturas',
      DOWNLOAD_INVOICE_BATCH_TITLE: 'Descarga facturas batch',
      DOWNLOAD_PAYROLL_TITLE: 'Descarga por lotes nómina',
      DOWNLOAD_PAYROLL_BATCH_TITLE: 'Descarga por lotes nómina',
      DOWNLOAD_RETENTION_TITLE: 'Descarga retencion',
      DOWNLOAD_RETENTION_BATCH_TITLE: 'Descarga retencion batch',
      FILTER_TITLE: 'Filtrar por',
      TYPE: 'Tipo',
      START_DATE: 'Fecha inicial',
      END_DATE: 'Fecha final',
      KEYWORD: 'Palabra clave',
      FOLIO_START: 'Folio min',
      FOLIO_END: 'Folio max',
      COMPANY: 'Empresa',
      CUSTOMER: 'Nombre Del Cliente',
      DOCUMENT_SUCCESS: 'CFDIS exitosos',
      DOCUMENT_CANCELLED: 'CFDIS cancelados',
      VERSION_1: 'Versión 1.0',
      VERSION_2: 'Versión 2.0',
      VERSION_3: 'Versión 3.3',
      VERSION_4: 'Versión 4.0',
      DOWNLOAD_XML: ' Descargar XML',
      DOWNLOAD_PDF: 'Descargar PDF',
      DOWNLOAD_XML_PDF: 'Descargar XML/PDF',
      DOWNLOAD_SELECTED_CANCEL_PDF: 'Descargar Acuse de cancelación',
      SEND_XML_PDF_TO_MAIL: 'Enviar correo electrónico',
      CANCEL_SERVICE: 'Cancelar CFDI ',
      DOWNLOAD_HEAD: 'Alerta',
      DOWNLOAD_TOOLTIP:
        'Por favor marque la casilla de los registros requeridos y a continuación presione alguna de las opciones para descargar xml, pdf, ambos documentos o cancelar CFDI’s.',
      ADD_RECORD: 'Añadir registro',
      PREVIOUS: 'Anterior',
      NEXT: 'Siguiente',
      NO_RECORD: 'Ningún dato disponible en esta tabla',
      SHOW_RECORD: 'Mostrar registros',
      NUM_CFDI: 'Disponibles CFDI',
      NUM_API: 'Disponibles API/BATCH',
      CONFIRM: 'Continuar',
      MONTH: 'Mes',
      FOLIO_SERIES: 'Serie/Folio CFDI pago',
      PAYMENT_DETAILS: 'Detalles del pago',
      PAYMENT_HEADING: '',
      INVOICE_DETAILS: 'Detalle por Factura',
      ERROR: {
        TYPE: 'Tipo es requerida',
        START_DATE: 'Fecha inicial es requerida',
        END_DATE: 'Fecha final es requerida',
        DOCUMENT: 'CFDIS typo es requerida',
        VERSION: 'Versión  es requerida',
        ROW_ERROR: 'Por favor seleccione registros',
        OK: 'De acuerdo',
      },
      DETAILS_FIELD: {
        RFC: 'FRC',
        RECEPTOR: 'Razón social del receptor',
        UUID: 'UUID del CFDI',
        SERIE_FOLIO: 'Serie/Folio',
        SYMBOL: 'Simbologia para indicar si suma o resta (+) (-)',
        PAYMENT_AMOUNT: 'Monto origen/Pagos',
        AMOUNT: 'Saldo',
        BANK: 'Banco',
        ACCOUNT: 'Cuenta',
        FETCH: 'Fecha de Emisión',
        RECEPTOR_RFC: 'RFC del receptor',
        RECEPTOR_NAME: 'Nombre del receptor',
        PAYMENT_STATUS: 'Estatus de Pago',
        AMOUNT_ON_REPORT: 'Monto excedido',
      },
    },
    OPERATOR: {
      TITLE: 'Usuarios',
      ADD_TITLE: 'Añadir Usuarios',
      EDIT_TITLE: 'Modificar Usuario',
      MANAGE_TITLE: 'Administrador de Usuarios',
      FIELDS: {
        FIRST_NAME: 'Nombre',
        LAST_NAME: 'Apellido Paterno',
        MOTHER_LAST_NAME: 'Apellido Materno',
        EMAIL_ADDRESS: 'Correo electrónico',
        ACCESS_TYPE: 'Tipo de Acceso',
        PASSWORD: 'Contrase&ntilde;a',
        USERNAME: 'Nombre de usuario',
      },
    },
    ERROR: {
      REQUIRED: 'Este campo  es requerido.',
      TAX_REQUIRED: 'Por favor llene todos los campos para impuestos',
      AMOUNT_REQUIRED: 'El importe debe ser mayor que 0',
      INVALID_NUMBER: 'Número invalido.',
      USER_REQUIRED:
        'Nombre de usuario es requerido y debe tener 8 caracteres.',
      USER_SPACE_NOT_ALLOWED: 'No se permiten espacios.',
      USER_ALREADY_TAKEN: 'El nombre de usuario ya existe, intente nuevamente.',
      USER_SUCCESS_MESSAGE: 'Usuario modificado correctamente.',
      SEND_USER_CREDENTIAL_MESSAGE:
        'Se han enviado los datos de acceso del usuario por correo electrónico. Verifique su bandeja de entrada.',
      CHECK_ALL_REQUIRED_FIELDS:
        'Por favor marque todos los campos obligatorios.',
    },
    CUSTOMER: {
      TITLE: 'Cliente',
      CUSTOMER_ADDRESS: 'Dirección de Cliente',
      ADD_TITLE: 'Añadir registro de Cliente',
      ADD_MORE: 'Añadir m&aacute;s registros',
      EDIT_TITLE: 'Modificar registro de Cliente',
      MANAGE_TITLE: 'Administrar',
      NEW: 'Nueva',
      FIELDS: {
        RFC: 'RFC',
        NAME: 'Nombre del receptor',
        INTERIOR_NUMBER: 'Número interior',
        REGISTERED_TAX_ID: 'Número de registro Id Tributario',
        CFDI_USE: 'Uso CFDI',
        STREET: 'Calle',
        COMPANY_ID: 'Empresa a la que se asigna este receptor',
        EXTERIOR_NUMBER: 'Número Exterior',
        COLONY: 'Colonia',
        LOCATION: 'Localidad',
        ADDRESS_REFERENCE: 'Referencia',
        DELEGATION: 'Delegación o Municipio',
        COUNTRY: 'Residencia Fiscal',
        STATE: 'Estado',
        OTHER_STATE: 'Otros Estados',
        POSTAL_CODE: 'Código Postal',
        TELEPHONE: 'Teléfono',
        EMAIL_ADDRESS: 'Correo Electrónico',
        WEBSITE: 'Página Web',
        USE_BRANCH: 'Dirección de Sucursal',
        BRANCH_LABEL: '¿Utilizará una dirección de sucursal de su Cliente?',
        ACTIVE: 'Activo',
        AUTOMATIC_MAIL: 'Envío automático a Correo Electrónico',
        HTML_TEMPLATE: 'Seleccione Plantilla PDF',
        ENABLED: 'Habilitado',
        ACTIVE_FOR_ALL_COMPANIES: 'Permitir usar a este cliente en todas sus empresas',
        IS_PDF_IN_ENGLISH: 'Generar PDF en inglés para este receptor',
        BOARD_TYPE: 'Régimen Fiscal',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de Cliente creado correctamente',
        EDIT_SUCCESS: 'Registro de Cliente modificado correctamente',
      },
    },
    PRODUCT: {
      TITLE: 'Productos',
      ADD_TITLE: 'Añadir Productos',
      EDIT_TITLE: 'Modificar Productos',
      MANAGE_TITLE: 'Administrar',
      NEW: 'Nueva',
      FIELDS: {
        INTERNAL_NAME: 'Nombre del producto',
        PRODUCT_CATALOG_ID: 'Clave de producto o servicio',
        IDENTIFICATION_NUMBER: 'Número de identificación',
        MEASUREMENT: 'Clave unidad de medida',
        COMPANY_ID: 'Nombre de la empresa',
        PRODUCT_CODE: 'Código de producto',
        MEASURE: 'Clave unidad de medida',
        UNIT_OF_MEASURE: 'Unidad de medida',
        DESCRIPTION: 'Descripción',
        ACTIVE: 'Activo',
        UNIT_PRICE: 'Precio unitario',
        CURRENCY_ID: 'Tipo de moneda',
        IEPS_ACTIVE: 'activar IEPS',
        IEPS_PERCENTAGE: 'Porcentaje de IEPS',
        CUSTOM_VAT: 'IVA predeterminado',
        ENABLED: 'Habilitado',
        TRANSFER_IVA: 'Tipo impositivo (IVA)',
        TRANSFER_IEPS: 'Tipo impositivo (IEPS)',
        RETENTION_IVA: 'Tipo impositivo (Retención IVA)',
        RETENTION_IEPS: 'Tipo impositivo (Retención IEPS)',
        RETENTION_ISR: 'Tipo impositivo (Retención ISR)',
        ACTIVE_TAX: 'Activar tipo impositivo Exento',
        TAX_OBJECT_CATEGORY: 'Objeto de impuesto',
        PERCENTAGE: 'Ingrese el porcentaje de ieps',
        PERCENTAGE_REQUIRED: 'Personalizado es requerido',
        PERCENTAGE_NUMBER_CHECK: 'El porcentaje debe ser un número',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de producto guardado correctamente',
        EDIT_SUCCESS: 'Registro de producto modificado correctamente',
      },
    },
    PART: {
      TITLE: 'Parte',
      ADD_TITLE: 'Añadir registro de Parte',
      EDIT_TITLE: 'Modificar registro de Parte',
      MANAGE_TITLE: 'Administrar',
      NEW: 'Nueva',
      FIELDS: {
        INTERNAL_NAME: 'Nombre de parte',
        PRODUCT_CATALOG_ID: 'Clave de producto o servicio',
        IDENTIFICATION_NUMBER: 'Número de identificación',
        MEASUREMENT: 'Clave unidad de medida',
        COMPANY_ID: 'Nombre de la empresa',
        PRODUCT_CODE: 'Código de producto',
        UNIT_OF_MEASURE: 'Unidad de medida',
        DESCRIPTION: 'Descripción',
        ACTIVE: 'Activo',
        UNIT_PRICE: 'Precio unitario',
        CURRENCY_ID: 'Tipo de moneda',
        IEPS_ACTIVE: 'activar IEPS',
        IEPS_PERCENTAGE: 'Porcentaje de IEPS',
        CUSTOM_VAT: 'IVA predeterminado',
        ENABLED: 'Habilitado',
        QUANTITY: 'Cantidad',
        IVA_PERCENTAGE: 'IVA Predeterminado',
        RETENTION_PERCENTAGE: 'Porcentaje de retencion',
        MEASUREMENT_TITLE: 'Unidad de medida',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de parte guardado correctamente',
        EDIT_SUCCESS: 'Registro de parte modificado correctamente',
      },
    },
    TAX: {
      TITLE: 'Administrar impuestos',
      ADD_TITLE: 'Añadir registro de impuesto',
      EDIT_TITLE: 'Editar impuesto',
      MANAGE_TITLE: 'Administrar',
      NEW: 'Nueva',
      FIELDS: {
        TAX_NAME: 'Nombre de impuesto',
        PERCENTAGE: 'Porcentaje',
        CODE: 'Impuesto',
        BILLING_METHOD: 'Tipo de factor',
        TAX_TYPE: 'Tipo impositivo',
        TYPE: 'Tipo de impuesto',
        FEDERAL: 'Impuestos Federales',
        OTHER: 'Otros impuestos',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de impuesto creado correctamente',
        EDIT_SUCCESS: 'Registro de impuesto modificado correctamente',
      },
    },
    ADDENDA: {
      TITLE: 'Addenda',
      ADD_TITLE: 'Añadir Addenda',
      EDIT_TITLE: 'Editar Addenda',
      DELETE_TITLE: 'Borrar Addenda',
      FIELDS: {
        ATTRIBUTE: 'Título',
        TITLE: 'Título',
        VALUE: 'Contenido',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Addenda creada correctamente',
        EDIT_SUCCESS: 'Addenda creada correctamente',
      },
    },
    EMPLOYEE: {
      TITLE: 'Empleado',
      ADD_TITLE: 'Añadir Empleado',
      EDIT_TITLE: 'Modificar Empleado',
      MANAGE_TITLE: 'Administraci&oacute;n',
      NEW: 'Nueva',
      FIELDS: {
        NAME: 'Nombre',
        EMAIL: 'Correo Electrónico',
        RFC: 'RFC',
        MASTER: 'ID',
        JOB_TYPE: 'Tipo de jornada',
        OFFICIAL_JOB_TYPE_ID: 'Tipo de régimen de contratación',
        EMPLOYEE_COMPANY_NUMBER: 'Número de empresa empleado ',
        EMPLOYEE_NUMBER: 'Número de Empleado',
        EMPLOYEE_CITIZEN_NUMBER_CURP: 'CURP del Empleado',
        SOCIAL_SECURITY_NUMBER: 'Número de Seguridad Social',
        BANK_OF_EMPLOYEE: 'Banco',
        EMPLOYEE_JOB_START_DATE: 'Fecha de inicio de relación laboral',
        JOB_POSITION: 'Puesto del Empleado',
        DEPARTMENT: 'Departamento',
        CONTRACT_TYPE: 'Tipo de Contrato',
        PAYMENT_PERIOD_TYPE: 'Periodo de Pago',
        JOB_RISK_TYPE: 'Riesgo de Trabajo',
        ACTIVE: 'Activo',
        DAILY_WAGE: 'Salario Diario',
        TOTAL_WAGE: 'Salario Diario Integrado',
        AUTHCODE: 'Código de Autenticación',
        POSTAL_CODE: 'Código postal del domicilio del empleado',
        CONTA_CONNECT: ' Enlace a Conta360',
        AUTOMATIC_SEND: 'Envío automático a Correo Electrónico',
        OUTSOURCING: 'Subcontratación',
        ADD_SUBCONTRACTOR: 'Añadir subcontratista',
        PERCENTAGE_TIME: 'Porcentaje de Tiempo',
        ADDITIONAL_EMPLOYER_DATA: 'Datos adicionales del Patrón',
        EMPLOYER_REGISTER: 'Registro patronal',
        RFC_ORIGIN_PATTERN: 'RFC Origen del Patrón',
        SNCF: 'Entidad SNCF',
        RESOURCE_ORIGIN: 'Origen del recurso',
        RESOURCE_AMOUNT: 'Monto del Recurso propio',
        ACTION: 'Acciones o Titulos',
        MARKET_VALUE: 'Valor de mercado',
        GRANTED_PRICE: 'Precio al otorgarse',
        COMPANY_ID: 'Nombre de la empresa',
        ANTIQUITY: 'Antigüedad',
        SYNDICATED: 'Sindicalizado',
        SCHEMA_TYPE: 'Tipo de régimen',
        BANK_ACCOUNT: 'Cuenta bancaria',
        STATE: 'Entidad federativa',
        SELECT: 'Seleccione Uno',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de Empleado creado correctamente',
        EDIT_SUCCESS: 'Registro de Empleado modificado correctamente',
      },
    },
    DEPARTMENT: {
      TITLE: 'Áreas de la empresa o departamentos',
      ADD_TITLE: 'Añadir Departamento',
      EDIT_TITLE: 'Modificar Departamento',
      MANAGE_TITLE: 'Departamento',
      NEW: 'Nueva',
      FIELDS: {
        NAME: 'Nombre',
        MASTER: 'Master ID',
        COST_CENTER: 'Centro de Costos',
        ACTIVE: 'Activo',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de Departamento creado correctamente',
        EDIT_SUCCESS: 'Registro de Departamento modificado correctamente',
      },
    },
    EMPLOYEE_OTHER: {
      TITLE: 'Otros pagos del empleado',
      ADD_TITLE: 'Añadir registro de Otros pagos',
      EDIT_TITLE: 'Editar registro de Otros pagos.',
      MANAGE_TITLE: 'Gestionar',
      NEW: 'Nueva',
      FIELDS: {
        NAME: 'Nombre',
        INTERNAL_NAME: 'Nombre interno',
        DEDUCTION_KEY: 'Clave de deducción',
        EMPLOYEE_OTHER_TYPE_ID: 'Clave SAT Otros pagos',
        DESCRIPTION: 'Descripción',
        AMOUNT: 'Cantidad',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Otros empleados agregados con éxito',
        EDIT_SUCCESS: 'Empleado Otros Editado con éxito',
      },
    },
    EMPLOYEE_DISCOUNT: {
      TITLE: 'Deducciones del empleado',
      ADD_TITLE: 'Añadir descuento de empleado',
      EDIT_TITLE: 'Editar descuento de empleado',
      MANAGE_TITLE: 'Gestionar',
      NEW: 'Nueva',
      FIELDS: {
        NAME: 'Nombre',
        DISCOUNT_DESCRIPTION: 'Descripción',
        DISCOUNT_CODE: 'Código de descuento',
        AMOUNT: 'Cantidad',
        AMOUNT_EXEMPTED: 'Importe',
        EMPLOYEE_DISCOUNT_TYPE_ID: 'Tipo de descuento del empleado',
        ACTIVE: 'Activo',
        ISR: 'ISR',
        ENABLED: 'Habilitado',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Descuento de empleado agregado con éxito',
        EDIT_SUCCESS: 'Descuento de empleados con éxito',
      },
    },
    EMPLOYEE_EXPENSE: {
      TITLE: 'Percepciones del Empleado',
      ADD_TITLE: 'Añadir registro de percepción',
      EDIT_TITLE: 'Modificar registro de percepción',
      MANAGE_TITLE: 'Administrar',
      NEW: 'Nueva',
      FIELDS: {
        NAME: 'Nombre',
        DESCRIPTION: 'Descripción',
        PAYMENT_CODE: 'Clave de percepción',
        AMOUNT: 'Importe gravado',
        EMPLOYEE_EXEMPTED: 'Importe Exento',
        EMPLOYEE_EXPENSE_TYPE_ID: 'Clave Agrupadora DIMM',
        ACTIVE: 'Activo',
        ENABLED: 'Habilitado',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Registro de percepción creado correctamente',
        EDIT_SUCCESS: 'Registro de percepción modificado correctamente',
      },
    },
    RETENTION_RECEPTOR: {
      TITLE: 'Receptores retenciones',
      ADD_TITLE: 'Agregar Nuevo receptor retenciones',
      EDIT_TITLE: 'Editar receptor retenciones',
      DELETE_TITLE: 'Delete Retention Receptor',
      FIELDS: {
        POSTAL_CODE: 'Código postal del receptor',
        NATIONAL: 'Nacional',
        FOREIGN: 'Extranjero',
        IDENTIFICATION_NUMBER:
          'Número de identificación fiscal (Id tributario)',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Receptor creado exitosamente',
        EDIT_SUCCESS: 'Receptor actualizado correctamente',
      },
    },
    CERTIFICATE: {
      TITLE: 'Carga de Certificados',
      ADD_TITLE: 'Herramientas',
      MANAGE_TITLE: 'Carga de Certificados',
      NEW: 'Nueva',
      FIELDS: {
        CER_KEY: 'Cargar CER y KEY',
        CHANGE_CFDI_PASSWORD: 'Cambiar la contraseña CFDI',
        PREVIOUS_PASSWORD_FOR_CREATING_INVOICE: 'Contraseña anterior para creación de la factura',
        NEW_PASSWORD_FOR_CREATING_INVOICE: 'Nueva contraseña para creación de la factura',
        PRIVATE_KEY_PASS: 'Contraseña de llave privada de los archivos CER y KEY',
        CLEAR: 'Limpiar',
        CER: 'CER',
        KEY: 'KEY',
        SECRET_QUESTION: 'Pregunta Secreta',
        SECRET_ANSWER: 'Respuesta Secreta',
        EXPIRY_DATE: 'Fecha en que vencen los sellos digitales',
        RECOGNIZE: 'Acepto, reconozco y autorizo a los Proveedores Autorizados de Certificación o Auxiliares de Facturación utilizados por Factura360, por medio del cual cumplo con la obligación descrita en la regla I.2.7.2.1, publicada en el Diario Oficial de la Federación el 28 de diciembre del 2011 en la Resolución Miscelánea Fiscal para 2014.',
        IMPORTANT: 'Información Importante',
        IMPORTANT_TOP_MESSAGE: 'a) Ésta contraseña se usa cada vez que emita o cancele un documento fiscal. Por favor resguárdela y memorícela. En caso de olvidarla tendrá que volver a cargar sus certificados.',
        IMPORTANT_MIDDLE_MESSAGE: 'A continuación le ofrecemos un mecanismo para recordar su contraseña de creación de facturas, que le servirá en caso de que no la recuerde o cuando el sistema detecte que su contraseña no es la correcta.',
        IMPORTANT_BOTTOM_MESSAGE: 'Elija una de las siguientes preguntas y escriba la respuesta.Le sugerimos que la respuesta le ayude a recordar la contraseña que acaba de ingresar.',
        PASSWORD: 'contraseña de llave privada de sellos'
      },
      MESSAGE: {
        ADD_SUCCESS: 'Información modificada correctamente',
        EDIT_SUCCESS: 'Información modificada correctamente',
        INVALID_CERTIFICATE: 'Contraseña de certificado no válida',
        PAYROLL_ERROR1: 'Límite de la serie de nóminas de la empresa cruzada.',
        PAYROLL_ERROR2: 'Su cuenta no tiene folios disponibles, favor de adquirir un Nuevo paquete de folios',
        INVOICE_ERROR2: 'Su cuenta no tiene folios disponibles, favor de adquirir un Nuevo paquete de folios',
        PAYROLL_ERROR3: 'Error de servicio',
        PAYROLL_ERROR4: 'Master ha sido bloqueado.',
        PAYROLL_ERROR6: 'Contraseña de certificado no válida.'
      }
    },
  },
};
